/* eslint-disable no-unused-vars */
import socketio from 'socket.io-client';
import React, { useEffect, useRef, useState } from 'react';
import socketStore from './socketStore';
import { authStore } from './AuthProvider';
import { useNavigate } from 'react-router';
import {
  convType,
  DeleteStatus,
  DeleteStatusFromBackend,
  GroupEvents,
  UserRole,
} from 'src/utils/enum';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import mapStore from './mapStore';
import { useQuery } from 'react-query';
import { INDIVIDUAL_API_URL } from 'src/api/axios';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import { decodeGroupMessage } from 'src/helpers/helpers';
import { replaceMentions } from 'src/utils/chat';
import { isJson } from 'src/utils/javascript';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { PATH_CHAT } from 'src/routes/paths';
import { useDispatch, useSelector } from 'react-redux';
import { saveUserData } from 'src/redux/reducers/user/UserReducer';

const SocketURL = process.env.REACT_APP_SOCKET_URL;
export const SocketContext = React.createContext();

const SocketContextProvider = ({ children }) => {
  // const storeData = useStore();
  const [chatClient, setChatClient] = useState(null);
  // const [deletedConversations, setDeletedConversations] = useState([]);
  // const [profileId, setProfileId] = useState(null);
  const [connected, setConnected] = useState(false);
  const [isUpadte, setIsUpadte] = useState(false);
  const [onlineState, setOnlineState] = useState({});
  const [pathName, setPathName] = useState({});
  const setOnlineUser = socketStore((state) => state.setOnlineUser);
  const replaceMessageInChatList = socketStore(
    (state) => state.replaceMessageInChatList
  );
  const replaceMessageInChatListByChatId = socketStore(
    (state) => state.replaceMessageInChatListByChatId
  );
  const pushChatList = socketStore((state) => state.pushChatList);
  const setTypingUser = socketStore((state) => state.setTypingUser);
  const setChatLogs = socketStore((state) => state.setChatLogs);
  const setBusinessChatLogs = socketStore((state) => state.setBusinessChatLogs);
  const setBusinessConv = socketStore((state) => state.setBusinessConv);
  const setbusinessConversationData = socketStore(
    (state) => state.setbusinessConversationData
  );
  const setCloseConversation = socketStore(
    (state) => state.setCloseConversation
  );
  const setOpenConversation = socketStore((state) => state.setOpenConversation);
  const appendChatLogs = socketStore((state) => state.appendChatLogs);
  const updateMessageStatusOfChatLogs = socketStore(
    (state) => state.updateMessageStatusOfChatLogs
  );
  const updateUnreadCountInChatList = socketStore(
    (state) => state.updateUnreadCountInChatList
  );
  const setNewMessageRecive = socketStore(
    (state) => state.setNewMessageRecive
  );
  const replaceConvInChatList = socketStore(
    (state) => state.replaceConvInChatList
  );
  const [lastId, setLastId] = useState(null);
  const { _id: profileId } = authStore((state) => state.user);
  const { user } = authStore((state) => state);
  const userRole = authStore((state) => state.role);
  const chatList = socketStore((state) => state.chatList);
  const appendUserInChatList = socketStore(
    (state) => state.appendUserInChatList
  );
  const setInteraction = socketStore((state) => state.setInteraction);
  const interactionUser = socketStore((state) => state.interactionUser);
  const replaceDeleteMessage = socketStore(
    (state) => state.replaceDeleteMessage
  );
  const updateInteractionGroupInfo = socketStore(
    (state) => state.updateInteractionGroupInfo
  );
  const removeChatList = socketStore((state) => state.removeChatList);
  const checkUserExist = socketStore((state) => state.checkUserExist);
  const setUserLiveLocation = mapStore((state) => state.setUserLiveLocation);
  const replaceMessageInChatLogs = socketStore(
    (state) => state.replaceMessageInChatLogs
  );
  const replaceMessageReactionInChatLogs = socketStore(
    (state) => state.replaceMessageReactionInChatLogs
  );
  const setEditMessage = socketStore((state) => state.setEditMessage);
  const setReactionMessage = socketStore((state) => state.setReactionMessage);
  const {
    conversation,
    setIsLoadingConversation,
    setIsLoadingChats,
    setConversation,
  } = socketStore((state) => state);
  const { enqueueSnackbar } = useSnackbar();
  const axiosPrivate = useAxiosPrivate();
  const [hardNavigate, setHardNavigate] = useState(false);
  const navigate = useNavigate();
  // const { data: userProfileDataSocket } = useQuery(
  //   'userProfileSocket',
  //   async ({ signal }) => {
  //     return await axiosPrivate
  //       .get(INDIVIDUAL_API_URL.profile, { signal })
  //       .then((res) => res.data);
  //   },
  //   { refetchOnWindowFocus: false }
  // );


  const dispatch = useDispatch();
  const userProfileDataSocket = useSelector((state) => state.user.userData);
  useQuery(
    'userProfileSetting',
    async ({ signal }) => {
      return await axiosPrivate
        .get(INDIVIDUAL_API_URL.profile, { signal })
        .then((res) => res.data);
    },
    {
      refetchOnWindowFocus: false, onSuccess: (data) => {
        dispatch(saveUserData(data));
      }
    }
  );


  const getFormattedMessage = (rawMessage, conversationType) => {
    const isGroupConversation = conversationType === convType.GROUP;

    // Determine if the rawMessage is an attachment
    // For group conversations, check if rawMessage is JSON, and if the parsed message is JSON and an array
    // For individual conversations, just check if rawMessage is JSON and an array
    const isAttachment = isGroupConversation
      ? isJson(rawMessage) &&
      isJson(JSON.parse(rawMessage)?.message) &&
      Array.isArray(JSON.parse(JSON.parse(rawMessage)?.message))
      : isJson(rawMessage) && Array.isArray(JSON.parse(rawMessage));

    if (isAttachment) {
      return (
        <>
          <AttachmentIcon style={{ fontSize: 16, marginRight: 2 }} />
          attachment
        </>
      );
    }

    // If the message is not an attachment, replace mentions in the message
    // For group conversations, decode the group message before replacing mentions
    return replaceMentions(
      isGroupConversation ? decodeGroupMessage(rawMessage)?.message : rawMessage
    );
  };

  useEffect(() => {
    if (profileId && userRole !== UserRole.GUEST) {
      const initChat = () => {
        const client = socketio(SocketURL, {
          query: {
            userId: profileId,
          },
          transports: ['websocket'],
          reconnectionAttempts: 5,
          reconnectionDelayMax: 10000,
          upgrade: true,
          //reconnection: false,
          autoConnect: false,
          forceNew: true,
        });
        if (!client.connected) {
          client.connect();
        }
        setChatClient(client);

        client.on('connect', function () { });

        client.on('confirmConnect', function (data) {
          setConnected(true);
          client.emit('join', { userId: profileId });
        });

        client.on('online', function (data) {
          const { from: userId, status } = data;
          setOnlineUser({ receiverId: userId, status: status });
        });

        client.onAny(function (event, data) {
          if (event === 'messageStatus') {
            // Update the read status of the message
            updateMessageStatusOfChatLogs(data);
          }
        });

        client.on('typing', function (data) {
          const { from: userId, status, type } = data;
          setTypingUser({ receiverId: userId, typing: status, type });
        });

        // client.on('openChat', async function (data) {
        //   console.log('openChat-data--->', data);

        // });



        client.on('readMessages', function (data) {
          // console.log(`calling on front-end readMessages`, data);
          updateUnreadCountInChatList({
            receiverId: data.to,
            action: 'readAll',
          });
        });

        client.on('disconnect', (reason) => {
          if (reason === 'io server disconnect') {
            client.connect();
          } else if (reason === 'ping timeout reason') {
            client.connect();
          }
          //
        });
      };
      initChat();

      return () => {
        if (chatClient) {
          chatClient.off('online');
          chatClient.off('typing');
          chatClient.off('readMessages');
          chatClient.off('connect');
          chatClient.off('disconnect');
          chatClient.off('confirmConnect');
          chatClient.off('deleteConversation');
        }
      };
    }
    if (connected && (userRole === UserRole.GUEST || !profileId)) {
      setChatClient(null);
      setConnected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId, userRole]);

  const handleDeleteConversation = ({ chatId, conversationType }) => {
    if (pathName.friendId && connected) {
      const data = {
        userId: profileId,
        receiverIds: [pathName.friendId],
        chatId: chatId,
        isPersonalChat: conversationType === convType.USER,
      };
      chatClient.emit('deleteConversation', data, (ack) => {
        if (ack) {
          removeChatList({ chatId: data.chatId, userId: pathName.friendId });

          navigate('/chat');
        } else {
          console.error('Deletion not acknowledged by the server');
        }
      });
    }
  };

  // Other code...

  const getbusinessChat = (chatId) => {
    if (pathName.friendId && connected) {
      setIsLoadingChats(true);
      chatClient.emit(
        'getBusinessConversation',
        { chatId: chatId },
        function (data) {
          setbusinessConversationData(data);
          setIsLoadingChats(false);
        }
      );
    }
  };

  const handleCloseConversation = (chatId) => {
    if (pathName.friendId && connected) {
      chatClient.emit('closeConversation', { chatId: chatId }, (ack) => {
        if (ack) {
          setCloseConversation(ack);
        }
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleOpenConversation = (chatId) => {
    if (pathName.friendId && connected) {
      chatClient.emit('openConversation', { chatId: chatId }, (ack) => {
        if (ack) {
          setOpenConversation(ack);
        }
      });
    }
  };
  const sendMessageEvent = ({
    to,
    mId,
    event,
    chatId,
    type,
    from,
    ...other
  }) => {
    if (connected) {
      if (event === 'readAllMessages') {
        chatClient.emit('readMessages', {
          to: to,
          mId: mId,
          event: event,
          chatId: chatId,
          type: type,
          from: from,
        })
      }
      chatClient.emit('messageStatus', {
        to: to,
        mId: mId,
        event: event,
        chatId: chatId,
        type: type,
        from: from,
        messageHistory: [],
        time: new Date().getTime(),
        ...other,
      });
    }
  };

  useEffect(() => {
    if (connected) {
      chatClient.on('message', async function (data) {
        //TODO: unread count update on message
        //TODO::on message set notification => onclick of notification call create conversation
        const { mId, message, senderId, unreadCount, userName } = data;
        // const msgData = { mId, message, msgType, readStatus, deletedStatus, senderId, receiverId, convType: convType_, timeStamp };
        setNewMessageRecive(mId, convType.USER)
        if (_.isEmpty(pathName)) {

          enqueueSnackbar(
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <strong>{userName}</strong>
              <span>
                {getFormattedMessage(message, convType.USER, userName)}
              </span>
            </div>,
            {
              variant: 'success',
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              autoHideDuration: 4000,
              style: {
                whiteSpace: 'pre-wrap',
              },
            }
          );

          // updateUnreadCountInChatList({ receiverId: senderId, action: "plus" });
        }
        // console.log('pathName', pathName);

        sendMessageEvent({
          to: senderId,
          mId: mId,
          event: 'delivered',
          from: profileId,
          type: convType.USER,
        });

        if (
          !_.isEmpty(pathName) &&
          pathName?.path.includes('chat') &&
          senderId === pathName?.friendId
        ) {
          sendMessageEvent({
            to: senderId,
            mId: mId,
            event: 'readMessage',
            from: profileId,
            type: convType.USER,
          });
          appendChatLogs(data);
        }

        if (!_.isEmpty(pathName) && pathName?.path.includes('chat')) {
          // if (!checkUserExist(senderId, 'user')) {
          //   const response = await callUserConversaion(
          //     { to: senderId, from: profileId },
          //     false
          //   );
          // }

          replaceMessageInChatList({
            msgData: data,
            replaceId: senderId,
            unreadCount: unreadCount || 0,
            profileId,
          });
        }


        // console.log('---pathName--',pathName?.friendId);
        // console.log('---senderId--',senderId);
        updateUnreadCountInChatList({ receiverId: senderId, action: 'plus' });
        // if (
        //   !_.isEmpty(pathName) //&&
        //   // pathName?.path.includes('chat') &&
        //   // senderId !== pathName?.friendId
        // ) {
        //   console.log('---updateUnreadCountInChatList--');

        //   updateUnreadCountInChatList({ receiverId: senderId, action: 'plus' });
        // }
      });

      chatClient.on('businessMessage', function (data) {
        //TODO: unread count update on message
        //TODO::on message set notification => onclick of notification call create conversation

        const {
          businessId,
          businessUserId,
          chatId,
          mId,
          message,
          unreadCount,
          userName,
          userPhoto,
        } = data;
        setNewMessageRecive(mId, convType.BUSINESS)
        // const msgData = {
        //   mId,
        //   message,
        //   msgType,
        //   readStatus,
        //   deletedStatus,
        //   senderId,
        //   receiverId,
        //   convType: convType_,
        //   timeStamp,
        //   businessId,
        //   businessUserId,
        //   chatId,
        //   senderName: userName,
        //   senderImage: userPhoto,
        //   senderDesignation: 'beemz user',
        // };

        if (_.isEmpty(pathName)) {
          // Replace mentions in a message with a more readable format
          enqueueSnackbar(
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <strong>{userName}</strong>
              <span>
                {getFormattedMessage(message, convType.BUSINESS, userName)}
              </span>
            </div>,
            {
              variant: 'success',
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            }
          );
          updateUnreadCountInChatList({ chatId, action: 'plus' });
        }

        sendMessageEvent({
          to: businessUserId === profileId ? businessId : businessUserId,
          mId: mId,
          event: 'delivered',
          from: businessUserId !== profileId ? businessId : businessUserId,
          chatId: chatId,
          type: convType.BUSINESS,
          businessUserId: businessUserId,
        });

        if (!_.isEmpty(pathName) && pathName?.path.includes('chat')) {
          if (
            ['business', 'business-chat'].includes(pathName.type) &&
            [businessId, chatId].includes(pathName?.friendId)
          ) {
            sendMessageEvent({
              to: businessUserId === profileId ? businessId : businessUserId,
              mId: mId,
              event: 'readMessage',
              from: businessUserId !== profileId ? businessId : businessUserId,
              chatId: chatId,
              type: convType.BUSINESS,
              businessUserId: businessUserId,
            });
            appendChatLogs({
              ...data,
              senderName: userName,
              senderImage: userPhoto,
            });
          }
          callBusinessConversaion({ chatId }, false);

          replaceMessageInChatListByChatId({
            msgData: { ...data, senderName: userName, senderImage: userPhoto },
            chatId: chatId,
            unreadCount: unreadCount || 0,
          });

          if (![businessId, chatId].includes(pathName?.friendId)) {
            updateUnreadCountInChatList({ chatId: chatId, action: 'plus' });
          }
        }
      });

      chatClient.on('groupMessage', function (data) {
        //TODO::on message set notification => onclick of notification call create conversation

        const {
          chatId,
          mId,
          message,
          receiverId,
          senderId,
          groupId,
          unreadCount,
          userName,
          userPhoto,
          group,
        } = data;
        setNewMessageRecive(mId, convType.GROUP)
        // Fixes the group chat unread msg count, if the msg is come from the same user.
        if (_.isEmpty(pathName) && profileId !== senderId) {
          // If the JSON format is attachment, display attachment in the notification instead of showing JSON

          enqueueSnackbar(
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <strong>{userName}</strong>
              <span>
                {getFormattedMessage(message, convType.GROUP, userName)}
              </span>
            </div>,
            {
              variant: 'info',
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            }
          );

          updateUnreadCountInChatList({ chatId, action: 'plus' });
        }

        let updateGroupInfo;
        try {
          let decoded = decodeGroupMessage(message);

          if (decoded?.type === GroupEvents.DELETE_GROUP) {
            // Remove group from chat list if it is deleted by admin
            removeChatList({ groupId, chatId });

            // If the pathname type is 'group' and the receiverId matches the friendId from the pathname
            // Note: friendId represents the ID of the conversation, which could be either an end user ID or a group ID.
            if (
              pathName.type === 'group' &&
              receiverId === pathName?.friendId
            ) {
              navigate(PATH_CHAT.root);
            }

            // Terminate the further execution
            return;
          }

          //on create new group or during self
          // check member is in list or create conversation
          if (
            decoded?.type === GroupEvents.CREATE ||
            (decoded?.type === GroupEvents.ADD_MEMBER &&
              decoded.affectedUserId === profileId)
          ) {
            callGroupConversaion({ userId: profileId, groupId }, false);
          }

          // handle someone remove you

          if (
            decoded?.type === GroupEvents.REMOVE_MEMBER &&
            decoded.affectedUserId === profileId
          ) {
            removeChatList({ groupId, chatId });
            if (
              pathName.type === 'group' &&
              receiverId === pathName?.friendId
            ) {
              setHardNavigate(true);
            }
            return true;
          }

          // update group info on update from other user end
          if (
            decoded?.type === GroupEvents.UPDATE_GROUP &&
            decoded.updatedGroup
          ) {
            updateGroupInfo = {
              name: decoded.updatedGroup?.title,
              image: decoded.updatedGroup?.image,
              description: decoded.updatedGroup?.description,
              geoTag: decoded.updatedGroup?.geoTag,
              isPrivate: decoded.updatedGroup?.isPrivate,
              isActive: decoded.updatedGroup?.isActive,
              groupId: groupId,
            };
          }
          // update group info on update from other user end
          if (
            [GroupEvents.INACTIVE_GROUP, GroupEvents.ACTIVE_GROUP].includes(
              decoded?.type
            )
          ) {
            updateGroupInfo = {
              isActive: decoded?.type === GroupEvents.ACTIVE_GROUP,
              groupId: groupId,
            };
          }
        } catch (er) {
          // console.log("group parsing error", er);
        }

        sendMessageEvent({
          to: senderId,
          mId: mId,
          event: 'delivered',
          from: receiverId,
          chatId: chatId,
          type: convType.GROUP,
        });

        if (!_.isEmpty(pathName) && pathName?.path.includes('chat')) {
          if (pathName.type === 'group' && receiverId === pathName?.friendId) {
            sendMessageEvent({
              to: senderId,
              mId: mId,
              event: 'readMessage',
              from: receiverId,
              chatId: chatId,
              type: convType.GROUP,
            });
            appendChatLogs({
              ...data,
              senderName: userName,
              senderImage: userPhoto,
            });
            if (updateGroupInfo) updateInteractionGroupInfo(updateGroupInfo);
          } else {
            // if the msg is come from the currently logged in user
            if (profileId !== senderId) {
              updateUnreadCountInChatList({
                chatId: chatId,
                action: 'plus',
              });

              // If the group doesn't exist in the chat list, add it
              const chatExists = chatList?.find(
                (chat) => chat?.groupId === groupId
              );
              const decoded = decodeGroupMessage(message);

              // If the group chat does not exist in the chat list and message type is normal message
              if (!chatExists && decoded?.type === GroupEvents.NORMAL_MESSAGE) {
                pushChatList([
                  {
                    canDelete: false,
                    chatId: chatId,
                    convType: data?.convType,
                    group: group,
                    groupId: groupId,
                    msgData: data,
                    unreadCount: 1,
                  },
                ]);
              }
            }
            if (updateGroupInfo)
              updateInteractionGroupInfo(updateGroupInfo, false);
          }
          replaceMessageInChatListByChatId({
            msgData: { ...data, senderName: userName, senderImage: userPhoto },
            chatId: chatId,
            unreadCount: unreadCount || 0,
          });
        }
      });

      chatClient.on(
        'messageStatus',
        function ({ to, mId, event, chatId, type, from, ...other }) {
          updateMessageStatusOfChatLogs({
            to,
            mId,
            event,
            chatId,
            type,
            from,
            ...other,
          });
        }
      );

      chatClient.on('deleteMessage', (data) => {
        replaceDeleteMessage({
          ...data,
          frontStatus:
            data.deletedStatus ?? DeleteStatusFromBackend.DeleteForBoth,
        });
      });

      chatClient.on('editMessage', (data) => {
        replaceMessageInChatLogs(data);
        // replaceDeleteMessage({ ...data, frontStatus: data.deletedStatus ?? DeleteStatusFromBackend.DeleteForBoth });
      });

      chatClient.on('reactionMessage', (data) => {
        let index = (data.reaction.length) - 1.

        

        console.log(" DEBUG index ",index)
        if (!data?.isRemove) {
          enqueueSnackbar(
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <strong>{data?.userName}</strong>
              {/* <span>
                  {getFormattedMessage(message, convType.GROUP, userName)}
                </span> */}
              <strong>
                {`${data?.reaction[index]?.reactionByName} is Rected ${data?.reaction[index]?.rectionEmoji} to ${data?.message}`}
              </strong>
            </div>,
            {
              variant: 'success',
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              autoHideDuration: 4000,
              style: {
                whiteSpace: 'pre-wrap',
              },
            }
          );
        }

        replaceMessageReactionInChatLogs(data);
        console.log("reactionMessage",data)
      });

      // chatClient.on('openChat', async function (data) {
      //   console.log('openChat-data--->', data);

      // });

      return () => {
        chatClient.off('message');
        chatClient.off('messageStatus');
        chatClient.off('businessMessage');
        chatClient.off('groupMessage');
        chatClient.off('deleteMessage');
        chatClient.off('editMessage');
        chatClient.off('reactionMessage');
        // chatClient.off('openChat');
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, pathName, chatList]);

  useEffect(() => {
    if (connected) {
      // Update the block and unblock status of the chat in real-time.
      chatClient.on('blockUnblock', ({ from, to, status }) => {
        // Update the block/unblock status only if it is related to the opened chat/conversation
        if (from === conversation.receiverId && to === conversation.senderId) {
          setConversation(
            status === 0
              ? { ...conversation, blockByThem: 0, blockByMe: 0 }
              : { ...conversation, blockByThem: status }
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, pathName, conversation]);

  useEffect(() => {
    if (connected) {
      chatClient.on('submitLocation', (data) => {
        setUserLiveLocation(data);
      });
      chatClient.on('requestLocation', (data) => {
        const userAddress = userProfileDataSocket?.address;
        let jsonData = { to: data.from, from: data.to, location: userAddress };
        chatClient.emit('submitLocation', jsonData);
      });
      return () => {
        chatClient.off('submitLocation');
        chatClient.off('requestLocation');
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);

  const messageDebounceTimer = useRef();
  useEffect(() => {
    if (pathName.friendId && connected) {
      try {
        if (!pathName.type) {
          const paramsObject = {
            userId: profileId,
            receiverId: pathName.friendId,
          };
          if (lastId) {
            paramsObject.lastId = lastId;
          }
          setIsLoadingChats(true);
          // Debounce this event to prevent multiple emissions when the user rapidly switches between chats.
          messageDebounceTimer.current = setTimeout(() => {
            chatClient.emit('getMessagesByLastId', paramsObject, (data) => {
              const recievedMsg = data
                ? data
                  .map((i) => ({
                    mId: i.mId,
                    message: i.message,
                    msgType: i.msgType,
                    readStatus: i.readStatus,
                    deletedStatus: i.deletedStatus,
                    senderId: i.senderId,
                    receiverId: i.receiverId,
                    convType: i.convType,
                    timeStamp: i.timeStamp || i.createdAt,
                    chatDatabaseId: i._id,
                    isForwarded: i?.isForwarded,
                    geoTag: i?.geoTag,
                    reaction: i?.reaction,
                    messageHistory: i?.messageHistory,
                    replyMessage: i?.replyMessage,
                    userName: i?.userName,
                    userPhoto: i?.userPhoto,
                    mentionedUser: i?.mentionedUser,
                    isEdited: i?.isEdited,
                  }))
                  .reverse()
                : [];
              if (!recievedMsg.length) setLastId(null);
              setChatLogs(recievedMsg, pathName.friendId, lastId);
              setIsLoadingChats(false);
            });
          }, 500);
        }

        if (
          ['business', 'business-chat', 'group'].includes(pathName.type) &&
          pathName.chatId
        ) {
          const paramsObject = { chatId: pathName.chatId };
          if (lastId) {
            paramsObject.lastId = lastId;
          }
          // if(interactionUser){
          //     paramsObject.convId = interactionUser.chatId;
          // }
          // debugger
          chatClient.emit('conversationCheck', paramsObject, (data) => {
            setBusinessConv(data);
          });
          setIsLoadingChats(true);
          chatClient.emit(
            'getMessagesUsingChatIdByLastId',
            paramsObject,
            (data) => {
              const recievedMsg = data
                ? data
                  .map((i) => ({
                    mId: i.mId,
                    message: i.message,
                    msgType: i.msgType,
                    readStatus: i.readStatus,
                    deletedStatus: i.deletedStatus,
                    senderId: i.senderId,
                    receiverId: i.receiverId,
                    convType: i.convType,
                    timeStamp: i.timeStamp || i.createdAt,
                    chatDatabaseId: i._id,
                    isForwarded: i?.isForwarded,
                    senderName: i?.user?.name,
                    senderImage: i?.user?.image,
                    geoTag: i?.geoTag,
                    senderDesignation: i?.user?.designation ?? 'beemz user',
                    reaction: i?.reaction,
                    messageHistory: i?.messageHistory,
                    replyMessage: i?.replyMessage,
                    userName: i?.userName || i?.user?.name,
                    userPhoto: i?.userPhoto,
                    mentionedUser: i?.mentionedUser,
                    isEdited: i?.isEdited,
                    // createdAt:new Date()
                  }))
                  .reverse()
                : [];
              if (!recievedMsg.length) setLastId(null);
              setBusinessChatLogs(recievedMsg, pathName.chatId, lastId);
              setIsLoadingChats(false);
            }
          );
        }
      } catch (error) {
        // console.log(error.message);
      }
    }

    return () => {
      clearTimeout(messageDebounceTimer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, pathName, lastId, isUpadte]);

  const isUserExist = (id) => {
    const checkExistRecieverIndex = chatList.findIndex(
      (el) => el.user?.userId === id
    );
    return checkExistRecieverIndex !== -1;
  };

  const conversationDebounceTimer = useRef();
  async function callUserConversaion({ to, from }, onPageCreate = true) {
    // Debounce this event to prevent multiple emissions when the user rapidly switches between chats.
    clearTimeout(conversationDebounceTimer.current);
    conversationDebounceTimer.current = setTimeout(async () => {
      if (!to || !from) return false;
      const userConvo = new Promise((resolve, reject) => {
        try {
          chatClient.emit(
            'createConversation',
            { to, from, type: convType.USER },
            function (data) {
              setConversation(data);
              if (onPageCreate) {
                sendMessageEvent({
                  to: to,
                  event: 'readAllMessages',
                  from: profileId,
                  type: convType.USER,
                  // Send this chatId for mobile to align the everything
                  chatId: data.chatId,
                  // Added the time to set read/deleiver time on mobile side
                  time: new Date().getTime(),
                });
              }

              const listAppend = {
                user: {
                  name: data.user?.name,
                  image: data.user?.image,
                  userId: data.user?.user_id ?? data.user._id,
                  isTyping: 0,
                  isOnline: +data.online,
                  type: convType.USER,
                  chatId: data._id,
                },
                msgData: {
                  mId: data?.mId,
                  message: data?.message,
                  msgType: data?.msgType,
                  readStatus: data?.readStatus,
                  deletedStatus: data?.deletedStatus,
                  senderId: data?.senderId,
                  receiverId: data?.receiverId,
                  convType: data?.convType,
                  timeStamp: data?.createdAt,
                  isForwarded: data?.isForwarded,
                  reaction: data?.reaction,
                  messageHistory: data?.messageHistory,
                  replyMessage: data?.replyMessage,
                  userName: data?.userName,
                  userPhoto: data?.userPhoto,
                },
                convType: convType.USER,
                unreadCount: data?.unreadCount || 0,
                chatId: data._id,
              };

              if (!isUserExist(data.receiverId))
                appendUserInChatList(listAppend, true);
              if (onPageCreate) {
                setInteraction(listAppend.user);
                setLastId(null);
                updateUnreadCountInChatList({
                  receiverId: to,
                  action: 'readAll',
                });
              }
              resolve(true);
            }
          );
        } catch (err) {
          reject(new Error(err.message));
        }
      });
      try {
        setIsLoadingConversation(true);
        await Promise.all([userConvo]);
        setIsLoadingConversation(false);
        return true;
      } catch (error) {
        setIsLoadingConversation(false);
        // console.log(error);
      }
    }, 500);
  }

  function callBusinessConversaion(
    { to, from, chatId, employeeId, chatCategory, chatCategoryId },
    onPageCreate = true
  ) {
    // Debounce this event to prevent multiple emissions when the user rapidly switches between chats.
    clearTimeout(conversationDebounceTimer.current);
    conversationDebounceTimer.current = setTimeout(async () => {
      // Modify the paramsObject to include chatCategory
      let paramsObject;
      if (chatId) {
        paramsObject = {
          chatId,
          type: convType.BUSINESS,
          chatCategory,
          chatCategoryId,
        };
      } else {
        paramsObject = {
          to,
          from,
          type: convType.BUSINESS,
          chatCategory,
          chatCategoryId,
        };
        if (employeeId) {
          paramsObject.employee = 1;
          paramsObject.employeeId = employeeId;
        }
        paramsObject.businessAddressId = '';
      }

      if (!onPageCreate && chatId) {
        const checkExistConversation = chatList?.some(
          (element) => element.chatId === chatId
        );
        if (checkExistConversation) return;
      }

      setIsLoadingConversation(true);
      chatClient.emit(
        'createBusinessConversation',
        paramsObject,
        function (data) {
          console.log("data createBusinessConversation", data)
          setConversation(data);
          setIsLoadingConversation(false);
          if (onPageCreate) {
            sendMessageEvent({
              to:
                data.businessUserId === profileId
                  ? data.businessId
                  : data.businessUserId,
              mId: data.mId,
              event: 'readAllMessages',
              from:
                data.businessUserId !== profileId
                  ? data.businessId
                  : data.businessUserId,
              chatId: data.chatId,
              type: convType.BUSINESS,
              businessUserId: data.businessUserId,
              chatCategoryId: chatCategoryId,
              // Added the time to set read/deleiver time on mobile side
              time: new Date().getTime(),
            });
          }

          const listAppend = {
            user: {
              name: data.user?.name,
              image: data.user?.image,
              userId: data.user?.user_id,
              isTyping: 0,
              isOnline: +data.online,
            },
            business: {
              name: data.business?.name,
              image: data.business?.image,
              businessId: data.businessId,
              isTyping: 0,
              isOnline: +data.online,
              type: convType.BUSINESS,
              businessUserId: data.businessUserId,
              senderId: data?.senderId,
              chatId: data.chatId,
              queryByUser:
                data?.senderId === profileId
                  ? data.business?.name
                  : `${data.user.name}@${data.business.name}`,
            },
            msgData: {
              mId: data?.mId,
              message: data?.message,
              msgType: data?.msgType,
              readStatus: data?.readStatus,
              deletedStatus: data?.deletedStatus,
              senderId: data?.senderId,
              businessId: data?.businessId,
              convType: data?.convType,
              timeStamp: data?.createdAt,
              isForwarded: data?.isForwarded,
              reaction: data?.reaction,
              messageHistory: data?.messageHistory,
              replyMessage: data?.replyMessage,
              userName: data?.userName,
              userPhoto: data?.userPhoto,
            },
            convType: convType.BUSINESS,
            employeeData: data?.employeeData,
            employee: data?.employee,
            unreadCount: data?.unreadCount || 0,
            queryByUser: data?.senderId === profileId,
            chatId: data.chatId,
            businessUserId: data.businessUserId,
            chatCategory: paramsObject.chatCategory, // Add chatCategory to the listAppend object
            chatCategoryId: paramsObject.chatCategoryId, // Add chatCategory to the listAppend object
          };

          const checkExistConversation = chatList?.some(
            (element) => element.chatId === data.chatId
          );
          if (!checkExistConversation) {
            appendUserInChatList(listAppend);
          }

          if (onPageCreate) {
            setPathName((state) => ({ ...state, chatId: data.chatId }));
            setLastId(null);
            setInteraction(listAppend.business);
            updateUnreadCountInChatList({
              chatId: data.chatId,
              action: 'readAll',
            });
          }
        }
      );
    }, 500);
  }

  function callGroupConversaion(
    { userId, groupId, isJoin },
    onPageCreate = true
  ) {
    // Debounce this event to prevent multiple emissions when the user rapidly switches between chats.
    clearTimeout(conversationDebounceTimer.current);
    conversationDebounceTimer.current = setTimeout(async () => {
      if (!connected) return false;
      if (!userId || !groupId) return false;

      setIsLoadingConversation(true);

      chatClient.emit(
        'createGroupConversation',
        { userId, groupId, isJoin },
        function (data) {
          setConversation(data);
          setIsLoadingConversation(false);

          if (onPageCreate) {
            sendMessageEvent({
              to: groupId,
              mId: data.mId,
              event: 'readAllMessages',
              from: profileId,
              chatId: data.chatId,
              type: convType.GROUP,
              // Added the time to set read/deleiver time on mobile side
              time: new Date().getTime(),
            });
          }
          const listAppend = {
            group: {
              name: data.groupData?.name,
              image: data.groupData?.image,
              groupId: data.groupId,
              isPrivate: data.groupData?.private,
              memberCount: data.groupData?.memberCount,
              isActive: data.groupData?.isActive,
              createdById: data.groupData?.createdById,
              createdByName: data.groupData?.createdByName,
              isTyping: 0,
              isOnline: +data?.online,
              type: convType.GROUP,
              chatId: data.chatId,
              description: data.groupData?.description,
              geoTag: data.groupData?.geoTag,
            },
            msgData: {
              mId: data?.mId,
              message: data?.message,
              msgType: data?.msgType,
              readStatus: data?.readStatus,
              deletedStatus: data?.deletedStatus,
              senderId: data?.senderId,
              groupId: data?.groupId,
              convType: data?.convType,
              timeStamp: data?.createdAt,
              isForwarded: data?.isForwarded,
              reaction: data?.reaction,
              messageHistory: data?.messageHistory,
              replyMessage: data?.replyMessage,
              userName: data?.userName,
              userPhoto: data?.userPhoto,
            },
            groupId: data.groupId,
            convType: convType.GROUP,
            unreadCount: data?.unreadCount || 0,
            chatId: data.chatId,
          };

          const checkExistConversation = chatList?.some(
            (element) => element.chatId === data.chatId
          );
          if (!checkExistConversation) {
            appendUserInChatList(listAppend);
          }

          if (onPageCreate) {
            setPathName((state) => ({ ...state, chatId: data.chatId }));
            setLastId(null);
            setInteraction(listAppend.group);
            updateUnreadCountInChatList({
              chatId: data.chatId,
              action: 'readAll',
            });
          }
        }
      );
    }, 500);
  }

  const handleSendMessage = (msgData) => {
    try {
      // Print a message indicating that a message is being sent

      // Prevent pushing to chat log if message is forwarded
      if (!msgData?.isForwarded) appendChatLogs(msgData);

      if (msgData.convType === convType.USER) {
        replaceMessageInChatList({ msgData, replaceId: msgData.receiverId });
        // Adjust message data if location sharing is not permitted
        msgData = {
          ...msgData,
          geoTag: user?.permissions?.location?.notShared
            ? null
            : msgData.geoTag,
          chatId: conversation?.chatId,
        };
        console.log('message---->', msgData);

        // Send personal message
        chatClient.emit('message', msgData, function () {
          // Callback function
        });
        // console.log('conversation >>>>',conversation);
        const { mentioned, ...filteredMsgData } = msgData;

        const lastMessage = {
          user: msgData.senderId,
          msgData: {
            ...filteredMsgData,
            id: msgData?.mId,
            isSelected: null,
            businessUserId: null,
            businessChatCategoryId: null,
            businessId: null,
            isEdited: null,
            messageId: null,
            groupId: null,
            chatId: conversation?.chatId,
            isForwarded: null,
            createdDate: new Date().getTime(),
            updatedDate: new Date().getTime(),
            readDetails: {},
            deliveryDetails: {},
            reaction: null,
            fromCustomer: null,
            mentionedUser: []
          }
        };
        console.log('lastMessage', lastMessage)
        chatClient.emit('openChatSendMessage', lastMessage, function () {
          // Callback function
        });
        
        const openChatData={
          user: profileId,
          // conversationId: interactionUser.type == 1 ? conversation._id : conversation.chatId,
          conversationId: conversation.chatId,
          type: interactionUser.type,
          chatId: conversation.chatId,
          to:interactionUser.userId
        }
        console.log('openChat', lastMessage)
        chatClient.emit('openChat',openChatData );

      } else if (msgData.convType === convType.BUSINESS) {

        console.log('msgData.chatId: ', msgData.chatId);
        replaceMessageInChatListByChatId({ msgData, chatId: msgData.chatId });
        // Send business message
        chatClient.emit('businessMessage', msgData, function () {
          // Callback function
        });
      } else if (msgData.convType === convType.GROUP) {
        const groupChat = chatList.find((el) => el.chatId === msgData.chatId);
        replaceMessageInChatListByChatId({
          msgData,
          chatId: msgData.chatId,
        });
        // Send group message
        chatClient.emit(
          'groupMessage',
          { ...msgData, group: groupChat?.group },
          function () {
            // Callback function
          }
        );
      }
    } catch (error) {
      // Print any errors that occur during message sending
      console.error(
        'Error sending message:-----******************************************',
        error
      );
    }
  };

  function assignEmployee({ chatId, employeeId }) {
    if (!connected) return false;

    chatClient.emit('assignEmployee', { chatId, employeeId }, function (data) {
      if (data) {
        replaceConvInChatList(chatId, employeeId);
      }
    });
  }

  function messageDelete(data) {
    //replace message in store
    let frontStatus;
    if (data.type === DeleteStatus.deleteForEveryOne) {
      frontStatus = DeleteStatusFromBackend.DeleteForBoth;
    } else if (data.selfMessage) {
      frontStatus = DeleteStatusFromBackend.DeleteForSender;
    } else {
      frontStatus = DeleteStatusFromBackend.DeleteForReceiver;
    }
    replaceDeleteMessage({ ...data, frontStatus });
    chatClient.emit('deleteMessage', data, (data) => { });
  }

  const handleEditMessage = (msgData = {}) => {
    try {
      if (msgData?.message) {
        replaceMessageInChatLogs(msgData);
        chatClient.emit('editMessage', msgData, function (data) { });
      }
      setEditMessage({});
    } catch (error) { }
  };

  const handleReactionMessage = (msgData = {}) => {
    try {
      if (msgData?.reaction) {
        chatClient.emit('reactionMessage', msgData);
      }
      setReactionMessage({});
    } catch (error) {
      // console.error(error);
    }
  };

  const setUserInactiveStatus = (status) => {
    chatClient.emit('user-inactive', { status });
  };

  //TODO:removeAssignedEmployee
  //TODO:assignBusinessConversation to add employee front side

  return (
    <SocketContext.Provider
      value={{
        chatClient,
        connected,
        onlineState,
        setOnlineState,
        setPathName,
        pathName,
        lastId,
        setLastId,
        sendMessageEvent,
        callUserConversaion,
        callGroupConversaion,
        callBusinessConversaion,
        assignEmployee,
        handleSendMessage,
        messageDelete,
        setHardNavigate,
        hardNavigate,
        handleEditMessage,
        handleReactionMessage,
        handleDeleteConversation,
        getbusinessChat,
        handleCloseConversation,
        setUserInactiveStatus,
        isUpadte,
        setIsUpadte,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketContextProvider;
