import React, { useContext, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Popover,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowDownCircle from 'src/svgComponents/ArrowDownCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MenuIcon from '@mui/icons-material/Menu';
import ExchangeCircle from 'src/svgComponents/ExchangeCircle';
import HomeIcon from 'src/svgComponents/HomeIcon';
import useLogout from 'src/hooks/useLogout';
import { useSnackbar } from 'notistack';
import PostIcon from '@mui/icons-material/BallotOutlined';
import ChatIcon from '@mui/icons-material/ChatOutlined';
import {
  PATH_AUTH,
  PATH_CHATVOL2,
  PATH_DASHBOARD,
  PATH_GUEST,
  PATH_HOME_ALT,
  PATH_USER,
} from 'src/routes/paths';
import useStore, { authStore } from '../../contexts/AuthProvider';
import {
  BUSINESS_API_URL,
  INDIVIDUAL_API_URL,
  USER_API_URL,
} from 'src/api/axios';
import { useQuery, useMutation } from 'react-query';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import UserAvatar from '../common/UserAvatar';
import { UserRole } from 'src/utils/enum';
import files from 'src/helpers/helpers';
import RequestsPopup from '../user/RequestsPopup';
import RefRequestsPopup from '../user/ReferenceRequestPopup';
import Logo from './Logo';
import { PATH_CHAT } from 'src/routes/paths';
import AppTooltip from '../common/AppTooltip';
import ResponsiveMenu from './ResponsiveMenu';
import SerchUserHomePopup from '../user/SerchUserHomePopup';
import UserLinkListPopup from '../user/UserLinkListPopup';
import PerentRequestPopup from '../user/PerentRequestPopup';
import UserCircle from 'src/svgComponents/UserCircle';
import { withTranslation } from 'react-i18next';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import ActiveAccountSendRequestPopup from '../user/ActiveAccountSendRequestPopup';
import ApprovIcon from 'src/svgComponents/ApprovIcon';
import { SocketContext } from 'src/contexts/socketProvide';
import NotificationBell from '../navbar/NotificationBell';
import { useDispatch } from 'react-redux';
import { saveUserData, setAuthToken } from 'src/redux/reducers/user/UserReducer';
import { useSelector } from 'react-redux';
import { setBusinessProfileData, setConfigurableFieldData, setConversationList } from 'src/redux/reducers/app/AppReducer';
import CompleteVerificationDialog from 'src/pages/home/CompleteVerificationDialog';
import VerifyOTPDialog from 'src/pages/dialog/VerifyOTPDialog';
import { io } from 'socket.io-client';
import { store } from 'src/redux/store';


const MainNavbar = (props) => {
  const socketRef = useRef(null);
  const user = useSelector((state) => state.user.userData);

  const storeData = useStore();
  const businessId = useStore((state) => state.businessId);
  const [openReqPopup, setOpenReqPopup] = useState(false);
  const [openRefReqPopup, setOpenRefReqPopup] = useState(false);
  const [searchUserPopup, setSearchUserPopup] = useState(false);
  const [userLinkListPopup, setUserLinkListPopup] = useState(false);
  const [perentRequestListPopup, setPerentRequestListPopup] = useState(false);
  const [deleteCancelPopup, setDeleteCancelPopup] = useState(false);

  const setIsGuestContinue = authStore((state) => state.setIsGuestContinue);
  const [respoMenu, setRespoMenu] = useState(false);
  const [logOutAnchorEl, setLogOutAnchorEl] = useState(null);
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [filteredNotification, setFilteredNotification] = useState([]);
  const [isVerifyMessageDialog, setIsVerifyMessageDiloag] = useState(false)
  const [verifyDialogOpen, setVerifyDialogOpen] = useState(false)
  // const [conversationUnreadCountNew, setConversationUnreadCountNew] = useState()

  const conversationUnreadCountNew = useRef("");
  const location = useLocation();


  const { enqueueSnackbar } = useSnackbar();
  const logout = useLogout();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const axiosType = useAxiosPrivate({ type: 'business' });
  const setBusinessId = authStore((state) => state.setBusinessId);
  const changeRole = authStore((state) => state.updateRole);
  const setHasBusiness = authStore((state) => state.setHasBusiness);
  const setHasBusinessListApi = authStore((state) => state.setHasBusinessListApi);

  const { chatClient } = useContext(SocketContext);

  const userProfileData = useSelector((state) => state.user.userData);
  // const selectedConversation = useSelector((state) => state.app.selectedConversation);
  

  // Update ref whenever selectedConversation changes
  
  const hasBusinessListApi = authStore((state) => state.hasBusinessListApi);
  const role = authStore((state) => state.role);

  const fetchFriendRequestData = useCallback(async ({ signal }) => {
    if (role === UserRole.USER) {
      return await axiosPrivate.get(USER_API_URL.friendList, { signal }).then((res) => res.data);
    } else if (role === UserRole.BUSINESS) {
      return await axiosType.get(BUSINESS_API_URL.businessRequestList, { signal }).then((res) => res.data);
    }
    return [];
  }, [role, axiosPrivate, axiosType]);

  const { data: friendRequestData, refetch: friendRequestList } = useQuery('friendList', fetchFriendRequestData, { refetchOnWindowFocus: false });

  const fetchFriendRefRequestData = useCallback(async ({ signal }) => {
    if (role !== UserRole.GUEST) {
      return await axiosPrivate.get(USER_API_URL.referencesRequest, { signal }).then((res) => res.data);
    }
    return [];
  }, [role, axiosPrivate]);

  const { data: friendRefRequestData } = useQuery('friendRefReqList', fetchFriendRefRequestData, { refetchOnWindowFocus: false });

  const fetchNotificationCount = useCallback(async ({ signal }) => {
    return await axiosPrivate.get(INDIVIDUAL_API_URL.getNotificationCount, { signal }).then((res) => res.data);
  }, [axiosPrivate]);

  const { data: notificationCount, refetch: getNotificationCount } = useQuery('notificationCount', fetchNotificationCount, { refetchOnWindowFocus: false });

  const typesToInclude = useMemo(() => new Set(['2', '3', '4']), []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (notificationCount) {
      const newFilteredNotification = notificationCount.filter((item) => {
        if (typesToInclude.has(item.type)) {
          return false;
        }
        return true;
      });

      setFilteredNotification(newFilteredNotification);
    }
  }, [notificationCount, typesToInclude]);

  const fetchNotificationList = async ({ signal }) => {
    return await axiosPrivate.get(INDIVIDUAL_API_URL.latestNotification, { signal, params: { limit: 7 } }).then((res) => res.data);
  };

  const { data: notificationList } = useQuery('notificationListLatest', fetchNotificationList, { refetchOnWindowFocus: false });

  const fetchUserLinkListData = useCallback(async ({ signal }) => {
    if (role === UserRole.USER) {
      return await axiosPrivate.get(USER_API_URL.linkedRequest + storeData.user._id + '/' + 3, { signal }).then((res) => res.data);
    } else if (role === UserRole.BUSINESS) {
      return await axiosPrivate.get(USER_API_URL.linkedRequest + businessId + '/' + 2, { signal }).then((res) => res.data);
    }
    return [];
  }, [role, storeData.user._id, businessId, axiosPrivate]);

  const { data: userLinkListData, refetch: userRefechLinkListData } = useQuery('userLinkListAll', fetchUserLinkListData, { refetchOnWindowFocus: false });

  const fetchBusinessListData = useCallback(async ({ signal }) => {
    if (role !== UserRole.GUEST) {
      const businessData = await axiosPrivate.get(BUSINESS_API_URL.allBusiness, { signal }).then((res) => res.data);
      setHasBusiness(businessData.length > 0);
      setHasBusinessListApi(businessData.length > 0);
      return businessData;
    }
    return [];
  }, [role, axiosPrivate, setHasBusiness, setHasBusinessListApi]);

  const { data: businessListData, refetch: businessList } = useQuery('businessList', fetchBusinessListData, { refetchOnWindowFocus: hasBusinessListApi });


  const fetchUserPerentListData = useCallback(async ({ signal }) => {
    if (role === UserRole.USER) {
      return await axiosPrivate.get(INDIVIDUAL_API_URL.childIdVerify, { signal }).then((res) => res.data);
    }
    return [];
  }, [role, axiosPrivate]);

  const { data: userPerentListData, refetch: userRefectPerentListData } = useQuery('userPerentReqListData', fetchUserPerentListData, { refetchOnWindowFocus: false });

  const fetchUserProfileData = useCallback(async ({ signal }) => {
    return await axiosPrivate.get(INDIVIDUAL_API_URL.profile, { signal }).then((res) => res.data);
  }, [axiosPrivate]);

  const { refetch: fetchUserProfile } = useQuery('userProfileDataNavbar', fetchUserProfileData, {
    refetchOnWindowFocus: false, onSuccess: (data) => {
      dispatch(saveUserData(data));
    }
  },);



  useQuery(
    ['configurablefields'],
    async ({ signal }) => {
      return await axiosPrivate
        .get(INDIVIDUAL_API_URL.configurableField, { signal })
        .then((res) => res.data);
    },
    {
      refetchOnWindowFocus: false, onSuccess: (data) => {
        dispatch(setConfigurableFieldData(data));
      }
    }
  );

  useQuery(
    ['businessProfileSetting'],
    async ({ signal }) => {
      if (userProfileData?.businessId) {
        return await axiosType
          .get(BUSINESS_API_URL.businessProfile, { signal })
          .then((res) => res.data);
      }
      return [];
    },
    {
      refetchOnWindowFocus: false, onSuccess: (data) => {
        dispatch(setBusinessProfileData(data));
      }
    }
  );



  useEffect(() => {
    userRefechLinkListData();
    friendRequestList();
    userRefectPerentListData();
    getNotificationCount();
  }, [role, userRefechLinkListData, friendRequestList, userRefectPerentListData, getNotificationCount]);

  const handleRefPopupClose = useCallback(() => {
    setOpenReqPopup(false);
  }, []);

  const handleRefReqPopupClose = useCallback(() => {
    setOpenRefReqPopup(false);
  }, []);

  const handleSerchUserPopupClose = useCallback(() => {
    setSearchUserPopup(false);
  }, []);

  const handleUserLinkListPopupClose = useCallback(() => {
    setUserLinkListPopup(false);
  }, []);

  const handlePerentRequestPopupClose = useCallback(() => {
    setPerentRequestListPopup(false);
  }, []);

  const handleRespoMenuOnClose = useCallback(() => {
    setRespoMenu(false);
  }, []);

  const handleRespoMenuOnOpen = useCallback(() => {
    setRespoMenu(true);
  }, []);

  const logOutPopupOpen = Boolean(logOutAnchorEl);
  const logOutPopupId = logOutPopupOpen ? 'simple-popover' : undefined;

  const handleLogOutArrowClick = useCallback((event) => {
    setLogOutAnchorEl(event.currentTarget);
  }, []);

  const handleLogOutArrowClose = useCallback(() => {
    setLogOutAnchorEl(null);
  }, []);

  const handleLogout = useCallback(async () => {
    try {

      if (socketRef.current) {
        socketRef.current.off('connect');
        socketRef.current.off('notification');
        socketRef.current.off('unreadConversationsCount');
        socketRef.current.off('error');

        socketRef.current.disconnect();
        socketRef.current = null;
      }

      setLogOutAnchorEl(null);
      await logout();
      chatClient.disconnect();
      navigate(PATH_AUTH.login);
      dispatch(saveUserData(null));
      dispatch(setAuthToken(null));
      dispatch(setConversationList([]));
    } catch (error) {
      enqueueSnackbar(props.t('toast_message.unable_to_logout'), {
        variant: 'error',
      });
    }
  }, [logout, chatClient, handleLogOutArrowClose, navigate, props.t, enqueueSnackbar]);

  const userOpen = Boolean(userAnchorEl);
  const userId = userOpen ? 'simple-popover' : undefined;

  const handleUserClick = useCallback(async (event) => {
    setUserAnchorEl(event.currentTarget);
    await businessList();
  }, [businessList]);

  const handleUserClose = useCallback(() => {
    setUserAnchorEl(null);
  }, []);

  const userData = useCallback((data) => {
    handleUserClose();
    if (data?._id) {
      if (data?.isDeleted === 1) {
        setDeleteCancelPopup(true);
      } else {
        setBusinessId(data?._id);
        axiosType();
        changeRole(UserRole.BUSINESS);
        navigate(PATH_GUEST.root);
      }
    } else {
      changeRole(UserRole.USER);
      setBusinessId(null);
      navigate(PATH_GUEST.root);
    }
  }, [handleUserClose, setBusinessId, axiosType, changeRole, navigate]);

  const deleteCancelPopupClose = useCallback(() => {
    setDeleteCancelPopup(false);
  }, []);

  const profileClick = useCallback(() => {
    if (role === UserRole.BUSINESS) {
      navigate(PATH_USER.businessProfile, {
        state: { otherUserBusinessId: businessId },
      });
    } else {
      navigate(PATH_USER.userProfile);
    }
  }, [role, businessId, navigate]);

  const homeBunClick = useCallback(() => {
    navigate(PATH_HOME_ALT.root);
  }, [navigate]);

  const postClick = useCallback((ev) => {
    if (role === UserRole.GUEST) {
      setIsGuestContinue(true);
      ev.preventDefault();
      return false;
    } else {
      navigate(PATH_DASHBOARD.general.social);
    }
  }, [role, navigate, setIsGuestContinue]);

  const eventClick = useCallback((ev) => {
    if (role === UserRole.GUEST) {
      setIsGuestContinue(true);
      ev.preventDefault();
      return false;
    } else {
      navigate(PATH_DASHBOARD.general.events);
    }
  }, [role, navigate, setIsGuestContinue]);

  const chatVol2Click = () => {
    setTimeout(() => {
      navigate(PATH_CHATVOL2.root);
    }, 100);
  }

  const handleNotification = (data) => {
   
    const storeState = store.getState();
    const selectedConvo = storeState?.app?.selectedConversation || null;
    const isNotSameConvo = data?.metaData?.conversationId !== selectedConvo?._id;

    if (isNotSameConvo) {
      enqueueSnackbar(
        <div>
          <strong>{data.title}</strong>
          <br />
          {data.message}
        </div>,
        {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }

  }

  // Memoized socket connection setup
  const setupSocket = () => {

    try {
      socketRef.current = io(process.env.REACT_APP_VOL2_SOCKET_URL, {
        query: { userId: user._id },
        transports: ['websocket'],
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
      });

      // Handle successful connection
      socketRef.current.on('connect', () => {
        console.log('socket connected successfully')
      });

      socketRef.current.on('error', (err) => {
        console.log('error', err)
      });

      socketRef.current.emit('userOnline', {
        userId: user?._id,
      });

      socketRef.current.on('notification', handleNotification);

      socketRef.current.on('unreadConversationsCount', (data) => {
        conversationUnreadCountNew.current = data?.count
      })

      socketRef.current.emit('getUnreadConversations', {
        userId: user?._id,
      });


    } catch (err) {
      console.log('error---->', err)
    }

  };

  // Socket setup effect
  useEffect(() => {
    if (!user?._id) return;

    if (socketRef.current == null) {
      setupSocket();
    }

    const handleBeforeUnload = () => {
      if (socketRef.current) {

        socketRef.current.off('connect');
        socketRef.current.off('notification');
        socketRef.current.off('unreadConversationsCount');
        socketRef.current.off('error');

        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);


    return () => {
      handleBeforeUnload()

      window.removeEventListener("beforeunload", handleBeforeUnload);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id]);

  return (
    <>
      <div className="navbar_wrapper">
        <div className="navbar_container">
          <Logo />
          <div className="nav_menu">
            <div className="nav_menu_center">
              <List
                sx={{
                  padding: '0px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: { sm: '16px' },
                }}
              >
                <ListItem
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0px',
                  }}
                >
                  <AppTooltip
                    title={props.t('tooltip_text.home')}
                    placement={'bottom'}
                  >
                    <Button
                      onClick={homeBunClick}
                      sx={{
                        minWidth: { xsm: '40px', xs: 'auto' },
                        padding: { sm: 1, xs: 0.5 },
                      }}
                    >
                      <HomeIcon />
                    </Button>
                  </AppTooltip>
                </ListItem>
                <ListItem
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0px',
                  }}
                >
                  <AppTooltip
                    title={props.t('tooltip_text.post')}
                    placement={'bottom'}
                  >
                    <Button
                      onClick={postClick}
                      sx={{
                        minWidth: { xsm: '40px', xs: 'auto' },
                        padding: { sm: 1, xs: 0.5 },
                      }}
                    >
                      <PostIcon sx={{ color: '#ffffff' }} />
                    </Button>
                  </AppTooltip>
                </ListItem>
                {/* <ListItem
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0px',
                  }}
                >
                  <AppTooltip
                    title={props.t('tooltip_text.chat')}
                    placement={'bottom'}
                  >
                    <button
                      onClick={chatClick}
                      sx={{
                        minWidth: { xsm: '40px', xs: 'auto' },
                        padding: { sm: 1, xs: 0.5 },
                      }}
                      className="bg-transparent border-0"
                    >
                      { <ChatIcon sx={{ color: '#ffffff' }} /> }
                       {notificationCount &&
                        notificationCount.some((item) => ["2", "3", "4"].includes(item?.type)) &&
                        chatNotificationCount > 0 && (
                          <span className="icon-chat-button_badge">
                            {chatNotificationCount}
                          </span>
                        )} 
                      {totalChatNotificationCount > 0 && (
                        <span className="icon-chat-button_badge">
                          {totalChatNotificationCount}
                        </span>
                      )}
                    </button>
                  </AppTooltip>
                </ListItem> */}
                <ListItem
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0px',
                  }}
                >
                  <AppTooltip
                    // title={props.t('tooltip_text.chat')}
                    title={"chat-vol2"}
                    placement={'bottom'}
                  >
                    <button
                      onClick={chatVol2Click}
                      sx={{
                        minWidth: { xsm: '40px', xs: 'auto' },
                        padding: { sm: 1, xs: 0.5 },
                      }}
                      className="bg-transparent border-0"
                    >
                      <ChatIcon sx={{ color: '#ffffff' }} />
                      {location.pathname !== "/chat-vol2" && conversationUnreadCountNew.current > 0 && (
                        <span className="icon-chat-button_badge">
                          {conversationUnreadCountNew.current}
                        </span>
                      )}
                    </button>
                  </AppTooltip>
                </ListItem>
                <ListItem
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0px',
                  }}
                >
                  <AppTooltip
                    title={props.t('tooltip_text.event')}
                    placement={'bottom'}
                  >
                    <Button
                      onClick={eventClick}
                      sx={{
                        minWidth: { xsm: '40px', xs: 'auto' },
                        padding: { sm: 1, xs: 0.5 },
                      }}
                    >
                      <EventAvailableOutlinedIcon sx={{ color: '#ffffff' }} />
                    </Button>
                  </AppTooltip>
                </ListItem>

                {role !== UserRole.GUEST && (
                  <ListItem
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '0px',
                    }}
                  >
                    <AppTooltip
                      title={props.t('tooltip_text.profile')}
                      placement={'bottom'}
                    >
                      <Button
                        onClick={profileClick}
                        sx={{
                          minWidth: { xsm: '40px', xs: 'auto' },
                          padding: { sm: 1, xs: 0.5 },
                        }}
                      >
                        <UserCircle />
                      </Button>
                    </AppTooltip>
                  </ListItem>
                )}

                <ListItem
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0px',
                  }}
                >
                  <Box
                    sx={{
                      display: {
                        '@media (min-width:993px)': {
                          display: 'none',
                        },
                      },
                    }}
                  >
                    <NotificationBell
                      notificationCount={filteredNotification}
                      notificationList={notificationList}
                      sx={{
                        '&.MuiButton-root': {
                          minWidth: 0,
                          padding: '0 !important',
                        },
                      }}
                    />
                  </Box>
                </ListItem>
              </List>
            </div>
            <div className="nav_menu_right">
              <ul>
                {role !== UserRole.INACTIVEUSER && role !== UserRole.GUEST && (
                  <li>
                    <AppTooltip
                      title={props.t('tooltip_text.switch_account')}
                      placement={'bottom'}
                    >
                      <Button
                        disableRipple
                        onClick={(e) => handleUserClick(e)}
                        className="main_nav_config_btn"
                      >
                        <ExchangeCircle sx={{ color: '#ffffff' }} />
                      </Button>
                    </AppTooltip>
                    <Popover
                      id={userId}
                      open={userOpen}
                      anchorEl={userAnchorEl}
                      onClose={handleUserClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <h4 className="app_text_16_semibold app_text_black mb-0 p-3 pb-2 app_text_transform">
                        {props.t('main_navbar_text.switch_account')}
                      </h4>
                      <h4 className="app_text_14 app_text_black px-3 py-2 mb-0 app_text_transform">
                        {props.t('main_navbar_text.personal_profile')}s
                      </h4>
                      <ListItemButton
                        sx={{ '&:hover': { backgroundColor: '#efe5fd' } }}
                        onClick={(event) => {
                          localStorage.setItem('type1', 'user');
                          userData();
                        }}
                      >
                        <div className="d-flex align-items-center justify-content-start">
                          <UserAvatar
                            diameter={24}
                            imgSrc={files(storeData?.user?.profilePic, 'image')}
                            alternateSrc={
                              storeData?.user?.displayName ||
                              storeData?.user?.userName
                            }
                          />
                          <h4 className="app_text_12 app_text_black mb-0 ms-2 text-capitalize text-start text_limit_120">
                            {storeData?.user?.displayName ||
                              storeData?.user?.userName}
                          </h4>
                        </div>
                        {!storeData?.businessId && (
                          <span className="ms-auto">
                            <CheckCircleIcon
                              style={{ fontSize: 18, color: '#6200ee' }}
                            />
                          </span>
                        )}
                      </ListItemButton>
                      <Divider
                        className="my-2 mb-1"
                        style={{ borderColor: '#000' }}
                      />
                      <h4 className="app_text_14 app_text_black mb-0 px-3 pt-2 app_text_transform">
                        {props.t('main_navbar_text.business_profile')}
                      </h4>
                      <List className="switch_account_popover_content">
                        {businessListData && businessListData.length > 0 ? (
                          businessListData.map((business) => (
                            <ListItemButton
                              key={business._id}
                              onClick={(event) => {
                                localStorage.setItem('type1', 'business');
                                userData(business);
                              }}
                            >
                              <div className="d-flex align-items-center justify-content-start">
                                <UserAvatar
                                  diameter={24}
                                  imgSrc={files(business.image, 'image')}
                                  alternateSrc={Array.from(business.name)[0]}
                                />
                                <h4 className="app_text_12 app_text_black mb-0 ms-2 text-start text-capitalize text_limit_120">
                                  {business.name}
                                  <AppTooltip
                                    title={
                                      (business.isDocumentverified === 1)
                                        ? 'verified'
                                        : 'not verified'
                                    }
                                    placement={'bottom'}
                                  >
                                    <IconButton sx={{ marginLeft: '2px' }}>
                                      <ApprovIcon
                                        size={20}
                                        color={
                                          (business.isDocumentverified === 1)
                                            ? 'blue'
                                            : 'orange'
                                        }
                                      />
                                    </IconButton>
                                  </AppTooltip>
                                </h4>
                              </div>
                              {storeData?.businessId === business._id && (
                                <span className="ms-auto">
                                  <CheckCircleIcon
                                    style={{ fontSize: 18, color: '#6200ee' }}
                                  />
                                </span>
                              )}
                            </ListItemButton>
                          ))
                        ) : (
                          <ListItem className="app_text_12_semibold app_text_gray py-2 d-flex justify-content-center app_text_transform">
                            {props.t('main_navbar_text.no_account')}
                          </ListItem>
                        )}
                      </List>
                    </Popover>
                  </li>
                )}

                <li>
                  <AppTooltip
                    title={props.t('tooltip_text.user_profile')}
                    placement={'bottom'}
                  >
                    <Button
                      disableRipple
                      onClick={(e) => handleLogOutArrowClick(e)}
                      className="main_nav_config_btn"
                    >
                      <ArrowDownCircle />
                    </Button>
                  </AppTooltip>
                  <Popover
                    id={logOutPopupId}
                    open={logOutPopupOpen}
                    anchorEl={logOutAnchorEl}
                    onClose={handleLogOutArrowClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <div className="logout_popover_content">
                      <div className="d-flex align-items-center">
                        {(storeData?.user?.displayName ||
                          storeData?.user?.userName) && (
                            <UserAvatar
                              diameter={40}
                              imgSrc={files(storeData?.user?.profilePic, 'image')}
                              alternateSrc={
                                Array.from(
                                  storeData?.user?.displayName ||
                                  storeData?.user?.userName
                                )[0]
                              }
                            />
                          )}
                        <div className="d-flex flex-column ms-2">
                          <span className="app_text_14_500 app_text_black">
                            {storeData?.user?.displayName ||
                              storeData?.user?.userName}
                          </span>
                          <span className="app_text_12 app_text_gray">
                            {storeData?.user?.email}
                          </span>
                        </div>
                      </div>
                      <Button
                        variant="contained"
                        className="app_text_12_semibold app_bg_primary app_btn mt-4 app_text_transform"
                        onClick={handleLogout}
                      >
                        {props.t('btn_text.logout')}
                      </Button>
                    </div>
                  </Popover>
                </li>
                <li>
                  <NotificationBell
                    notificationCount={filteredNotification}
                    notificationList={notificationList}
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="responsive_btn_container">
            <Button
              onClick={() => handleRespoMenuOnOpen()}
              className="responsive_menu_btn"
            >
              <MenuIcon style={{ fontSize: '32px' }} />
            </Button>
          </div>
        </div>
      </div>
      <ResponsiveMenu
        storeData={storeData}
        businessListData={businessListData}
        files={files}
        respoMenu={respoMenu}
        onRespoMenuClose={handleRespoMenuOnClose}
        handleLogout={handleLogout}
        role={role}
        userDataFunc={userData}
      />
      <RequestsPopup
        friendRequestData={friendRequestData}
        open={openReqPopup}
        onClose={handleRefPopupClose}
        userProfileData={userProfileData}
        showVerify={() => { setIsVerifyMessageDiloag(true) }}
      />
      <RefRequestsPopup
        friendRefRequestData={friendRefRequestData}
        open={openRefReqPopup}
        onClose={handleRefReqPopupClose}
      />
      <UserLinkListPopup
        userLinkListData={userLinkListData}
        userProfileData={userProfileData}
        open={userLinkListPopup}
        onClose={handleUserLinkListPopupClose}
      />
      <PerentRequestPopup
        PerentRequestData={userPerentListData}
        open={perentRequestListPopup}
        onClose={handlePerentRequestPopupClose}
      />
      <SerchUserHomePopup
        userProfileData={userProfileData}
        open={searchUserPopup}
        onClose={handleSerchUserPopupClose}
      />
      <ActiveAccountSendRequestPopup
        open={deleteCancelPopup}
        onClose={deleteCancelPopupClose}
      />

      <CompleteVerificationDialog
        open={isVerifyMessageDialog}
        onClose={() => {
          setIsVerifyMessageDiloag(false)
        }}
        email={userProfileData?.email}
        onSuccess={() => {
          setIsVerifyMessageDiloag(false)
          setVerifyDialogOpen(true)
        }}
      />
      <VerifyOTPDialog
        open={verifyDialogOpen}
        onClose={() => setVerifyDialogOpen(false)}
        email={userProfileData?.email}
        userId={userProfileData?._id}
        onVerify={fetchUserProfile}
      />

    </>
  );
};

export default React.memo(withTranslation()(MainNavbar));