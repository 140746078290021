import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';
import { useQueryClient } from 'react-query';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import files from 'src/helpers/helpers';
import AppTooltip from '../common/AppTooltip';
import DirectionsIcon from '@mui/icons-material/Directions';
import ChatFilled from 'src/svgComponents/ChatFilled';
import { useNavigate } from 'react-router';
import { PATH_CHAT, PATH_USER } from 'src/routes/paths';
import UserAvatarWithNameAndID from './UserAvatarWithNameAndID';
import { useSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';

const UserLinkListRow = (props) => {
  const {
    userId,
    name,
    businessName,
    businessImage,
    businessLocation,
    businessOwner,
    userPic,
    userName,
    permissions,
    friendStatus,
    contact,
    address,
    businessId,
    unlinkStart,
    unLinkCheckFunc,
    unlinkChecked,
    userPermission,
    onClose,
  } = props;
  const UserButtons = ({ friendStatus }) => {
    const navigate = useNavigate();
    // eslint-disable-next-line no-unused-vars
    const axiosPrivate = useAxiosPrivate();
    // eslint-disable-next-line no-unused-vars
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const direction = ({ lat, long }) => {
      if (userPermission?.location?.notShared) {
        enqueueSnackbar(props.t('toast_message.give_location_permission'), {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          autoHideDuration: 2000,
        });
      } else {
        window.open(
          `https://www.google.com/maps/dir/?api=1&destination=${lat},${long}`
        );
      }
    };

    // const chatButton = (data) => {
    //   if (data?.businessId) {
    //     onClose();
    //     navigate(`${PATH_CHAT.root}/business-chat/${data?.businessId}`);
    //   } else {
    //     onClose();
    //     navigate(`${PATH_CHAT.root}/${data?.userId}`);
    //   }
    // }; 
    const chatButton = (data) => {
      onClose();
      navigate(`${PATH_CHAT.root}/business-chat/${data?.businessId}`);
    };

    const userPersonalChat = (data) => {
      const payload = {
        isGroup: false,
        users: [data]
      }
      console.log("DEBUG payload", payload);
      const eventDetailsEncodedPayload = encodeURIComponent(JSON.stringify(payload));
      // Navigate with URL parameter
      navigate(`/chat-vol2?oneToOneChatFromFromURL=${eventDetailsEncodedPayload}`);
      // navigate(`${PATH_CHAT.root}/${userSingleEventDetails?.userId}`);
    };
  

    switch (friendStatus) {
      case 2:
      case 1:
        return (
          <Button
            className={`user_list_card_btn_link app_border_primary app_text_transform app_text_14_semibold app_text_primary app_bg_primary_light`}
            variant="contained"
          >
            {props.t('popup_btn.requested')}
          </Button>
        );

      case 4:
      case 0:
        return (
          <Button
            className={`user_list_card_btn_link app_text_transform app_text_14_semibold text-white app_bg_primary`}
            variant="contained"
          >
            {props.t('popup_btn.link')}
          </Button>
        );

      case 3:
        return (
          <>
            {permissions?.acceptMessage?.public ||
            (permissions?.acceptMessage?.contact && contact) ? (
              <AppTooltip
                title={props.t('tooltip_text.chat')}
                placement={'bottom'}
              >
                <Button
                  variant="contained"
                  className="user_list_card_btn ms-2"
                  onClick={() =>
                    // Old code
                    // chatButton(
                    //   businessId
                    //     ? { businessId: businessId }
                    //     : { userId: userId }
                    // )
                    // new code
                    userPersonalChat(userId)
                  }
                >
                  <ChatFilled color={'#6200EE'} size={18} />
                </Button>
              </AppTooltip>
            ) : (
              <></>
            )}
            {(permissions?.location?.withPublic ||
              (permissions?.location?.withLinkedContact &&
                friendStatus === 3)) &&
            !permissions?.location?.whileUsingApp &&
            !permissions?.location?.notShared &&
            permissions?.visibility?.enableNavigation ? (
              <AppTooltip
                title={props.t('tooltip_text.direation')}
                placement={'bottom'}
              >
                <Button
                  variant="contained"
                  className="user_list_card_btn ms-2"
                  onClick={() =>
                    direction(
                      businessId
                        ? {
                            lat: businessLocation?.coordinates[1],
                            long: businessLocation?.coordinates[0],
                          }
                        : {
                            lat: address?.location?.coordinates[1],
                            long: address?.location?.coordinates[0],
                          }
                    )
                  }
                >
                  <DirectionsIcon
                    style={{ color: '#6200EE', fontSize: '18px' }}
                  />
                </Button>
              </AppTooltip>
            ) : (
              <></>
            )}
          </>
        );
      default:
        return '';
    }
  };
  const navigate = useNavigate();
  const handlenavigationtoprofile = () => {
    props.onClose();
    navigate(PATH_USER.userProfile, { state: { otherUserId: userId } });
  };
  return (
    <ListItem
      // onClick={handlenavigationtoprofile}
      className="w-100"
      secondaryAction={<UserButtons friendStatus={friendStatus} />}
      disablePadding
      disableTouchRipple
    >
      <ListItemButton
        disableRipple
        disabled={friendStatus !== 3}
        sx={{ my: 0, py: 2 }}
        role={undefined}
      >
        {unlinkStart && (
          <ListItemIcon sx={{ minWidth: 'unset' }}>
            <Checkbox
              edge="start"
              checked={unlinkChecked.indexOf(userId) !== -1}
              tabIndex={-1}
              onClick={() => unLinkCheckFunc(userId)}
              // disableRipple
            />
          </ListItemIcon>
        )}
        <Box sx={{ width: '76%' }} className="d-flex flex-column"
          onClick={() => {
            handlenavigationtoprofile()
          }}
        >
          <UserAvatarWithNameAndID
            userImg={
              permissions?.visibility?.picture ? files(userPic, 'image') : ''
            }
            businessImage={businessImage ? files(businessImage, 'image') : ''}
            businessId={businessId}
            avatarSize={50}
            userFullName={name}
            userId={userName}
            businessName={businessName}
            businessOwner={businessOwner}
          />
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

export default withTranslation()(UserLinkListRow);
