import {
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import files from 'src/helpers/helpers';
import { BUSINESS_API_URL } from 'src/api/axios';
import { useMutation, useQueryClient } from 'react-query';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import { useSnackbar } from 'notistack';

const DeleteLeaveReqPopup = (props) => {
  const { openPopup, onclose, empData } = props;

  const axiosPrivate = useAxiosPrivate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const completedSchema = Yup.object().shape({
    reason: Yup.mixed().required(props.t('validation.reason_req')),
  });

  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: completedSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      const deleteReqObj = {
        cancleReason: values.reason,
      };
      await empDeleteReq(deleteReqObj);
      setSubmitting(false);
      queryClient.invalidateQueries(['empAvailability']);
      queryClient.invalidateQueries(['leaveRequestList']);
    },
  });
  const { mutateAsync: empDeleteReq } = useMutation(
    async (data) => {
      return await axiosPrivate.post(
        BUSINESS_API_URL.deleteEmpReq + empData?._id,
        JSON.stringify(data)
      );
    },
    {
      onSuccess: (success) => {
        enqueueSnackbar(success.data.message, {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          autoHideDuration: 2000,
        });
        formik.resetForm();
        onclose();
      },
    }
  );

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Dialog
      onclose={onclose}
      open={openPopup}
      className="request_popup_wrapper"
    >
      <DialogTitle className="px-0 pt-0 text-center">
        <span className="app_text_20_500 app_text_transform">
          {props.t('popup_title.reject_request')}{' '}
        </span>
        <IconButton
          aria-label="close"
          onClick={onclose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <div className="row row-cols-1 g-4 mt-1">
            <Stack direction="row" alignItems="center">
              <Avatar
                src={files(
                  empData?.employeeImage,
                  'image'
                )} /* {files(user.image, 'image')} */
                alt="avatar"
                sx={{
                  mr: { xs: 1, sm: 0 },
                  mb: { sm: 2 },
                  width: { md: 64 },
                  height: { md: 64 },
                }}
              />
              <div>
                <Typography variant="subtitle2">
                  {empData?.businessName}
                </Typography>
              </div>
            </Stack>
            <div className="col">
              <TextField
                className="app_text_transform"
                id="reason"
                label={props.t('text_filed.reject_request_reason')}
                varient="outlined"
                {...getFieldProps('reason')}
                error={Boolean(touched.reason && errors.reason)}
                helperText={touched.reason && errors.reason}
                fullWidth
              />
            </div>
            <div className="col">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className="my-3 app_text_transform text-white app_bg_primary app_text_16_semibold app_btn_lg"
              >
                {props.t('btn_text.submit')}
              </Button>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default withTranslation()(DeleteLeaveReqPopup);
