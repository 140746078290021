import DoneIcon from '@mui/icons-material/Done';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
  Popover,
  styled,
} from '@mui/material';
import { withTranslation } from 'react-i18next';
import DeleteIcon from 'src/svgComponents/DeleteIcon';
import AppTooltip from '../common/AppTooltip';
import UserAvatar from '../common/UserAvatar';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { axiosPrivate, INDIVIDUAL_API_URL } from 'src/api/axios';
import { useNavigate } from 'react-router';
import { PATH_CHAT, PATH_DASHBOARD, PATH_USER } from 'src/routes/paths';
import files from 'src/helpers/helpers';

// Styled badge component
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.error.main,
    fontSize: '10px',
    fontWeight: 'bold',
  },
}));

const NotificationBell = ({
  t,
  notificationCount = [],
  notificationList = {},
  ...props
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const navigate = useNavigate();

  // React Query mutations
  const readAllNotificationsMutation = useMutation(() => axiosPrivate.put(INDIVIDUAL_API_URL.readAllNotification));
  const deleteNotificationsMutation = useMutation(() => axiosPrivate.put(INDIVIDUAL_API_URL.notificationDelete));
  const readNotificationMutation = useMutation((id) => axiosPrivate.put(INDIVIDUAL_API_URL.readNotification.replace(':id', id)));

  const unreadNotifications = notificationCount.reduce((acc, item) => acc + item.count, 0);
  const notificationPopupOpen = Boolean(notificationAnchorEl);

  // Event handlers
  const handleNotificationClick = (event) => setNotificationAnchorEl(event.currentTarget);
  const handleNotificationClose = () => setNotificationAnchorEl(null);
  const handleDeleteDialogOpen = () => setDeleteDialogOpen(true);
  const handleDeleteDialogClose = () => setDeleteDialogOpen(false);

  const handleReadAllClick = async () => {
    try {
      await readAllNotificationsMutation.mutateAsync();
      // window.location.reload();
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    }
  };

  const confirmDelete = async () => {
    try {
      handleDeleteDialogClose();
      await deleteNotificationsMutation.mutateAsync();
      await readAllNotificationsMutation.mutateAsync();
      window.location.reload();
    } catch (error) {
      console.error('Error deleting notifications:', error);
    }
  };

  const setReadNotification = async (id, item) => {
    console.log("setReadNotification", item)
    try {
      await readNotificationMutation.mutateAsync(id);
      if (item.data.postId) navigate(`${PATH_DASHBOARD.general.feedDetail}/${item.data.postId}`);
      else if (item.data.eventId) navigate(PATH_DASHBOARD.general.eventdetail, { state: { eventId: item.data.eventId } });
      else if (item.data.link) navigate('/home', { state: { showTraceBox: true, activePart: 2 } });
      else if (item.data.businessId && item.data.recipient) navigate(PATH_USER.businessProfile, { state: { otherUserBusinessId: item.data.businessId } });
      else if (item.data.businessId && item.data.stringify) {
        const data = JSON.parse(item.data.stringify);
        navigate(data.convType === 2 ? `${PATH_CHAT.root}/business/${item.data.businessId}` : PATH_CHAT.root);
      } else if (item.data.traceUserId) navigate('/home', { state: { showTraceBox: true, activePart: 1 } });
      else if (item.data.senderId && item.data.stringify) {
        const data = JSON.parse(item.data.stringify);
        navigate(data.convType === 1 ? `${PATH_CHAT.root}/${item.data.senderId}` : `${PATH_CHAT.root}/group/${data.groupId}`);
      }
    } catch (error) {
      console.error('Error setting notification as read:', error);
    }
  };

  return (
    <>
      <AppTooltip title={t('tooltip_text.notification')} placement="bottom">
        <Button disableRipple onClick={handleNotificationClick} {...props}>
          <StyledBadge badgeContent={unreadNotifications} color="error" overlap="circular">
            <NotificationsIcon style={{ color: '#fff' }} />
          </StyledBadge>
        </Button>
      </AppTooltip>

      <Popover
        open={notificationPopupOpen}
        anchorEl={notificationAnchorEl}
        onClose={handleNotificationClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="app_text_16_semibold mb-0 p-3">{t('notifications')}</h4>
          {notificationList.data?.length > 0 && (
            <div className="d-flex align-items-center">
              <AppTooltip title={t('tooltip_text.read_all_notification')} placement="bottom">
                <Button onClick={handleReadAllClick}>
                  <DoneIcon />
                </Button>
              </AppTooltip>
              <AppTooltip title={t('tooltip_text.delete_notification')} placement="bottom">
                <Button onClick={handleDeleteDialogOpen}>
                  <DeleteIcon />
                </Button>
              </AppTooltip>
            </div>
          )}
        </div>

        <div className="notification_popover_content">
          {notificationList.data
            ?.filter(({ data }) => ![2, 3, 4, 112, 113].includes(data.type))
            .map((item, i) => (
              <ListItem key={i} onClick={() => setReadNotification(item.notificationId, item)} sx={{ cursor: "pointer" }}>
                <div className="d-flex align-items-center">
                  <UserAvatar
                    diameter={40}
                    imgSrc={item.senderInfo.image ? files(item.senderInfo.image, 'image') : item.businessInfo?.image ? files(item.senderInfo.image, 'attachments')
                      : files(item.eventInfo?.image, 'attachments')

                    }
                    alternateSrc={item.senderInfo.name}
                  />
                  <div className="d-flex flex-column ms-2">
                    <h4 className={item.isRead ? 'app_text_14_500 app_text_gray' : 'app_text_14_500 app_text_black'}>{item.notification.title}</h4>
                    <span className="app_text_12_fw500 app_text_gray">{item.notification.body}</span>
                  </div>
                </div>
              </ListItem>
            ))}
        </div>
        <div style={{ padding: 5, display: "flex", justifyContent: "center" }}>
          <Button onClick={() => {
            navigate("/dashboard/notification")
            handleNotificationClose();
          }}
            sx={{ textTransform: "lowercase" }}
          >
            see all
          </Button>
        </div>
      </Popover>

      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>{t('delete_notifications_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('delete_notifications_message')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>{t('cancel')}</Button>
          <Button onClick={confirmDelete} autoFocus>{t('confirm')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withTranslation()(NotificationBell);
