import React from 'react';
import { Avatar, Button, ListItem, ListItemAvatar } from '@mui/material';
import files from 'src/helpers/helpers';
import UserAvatar from '../common/UserAvatar';
import { useMutation, useQueryClient } from 'react-query';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import {
  SOCIAL_MEDIA_URL,
} from 'src/api/axios';
import { withTranslation } from 'react-i18next';
import { authStore } from 'src/contexts/AuthProvider';
import { useNavigate } from 'react-router';
import { PATH_USER } from 'src/routes/paths';
const FollowersRow = (props) => {
  const {
    fullName,
    userPic,
    status,
    permissions,
    fromUser,
    toUser,
    userName,
    userDataProfile,
    openVerificationDialog
  } = props;
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  const { _id: profileId } = authStore((state) => state.user);

  const userFollowClick = async (data) => {

    if (userDataProfile.isVerify == 0) {
      if (openVerificationDialog) {
        openVerificationDialog()
      }
    } else {
      await userFollow(data);
    }
  };

  const { mutateAsync: userFollow } = useMutation(
    async (data) => {
      return await axiosPrivate.post(
        SOCIAL_MEDIA_URL.userFollow,
        JSON.stringify(data)
      );
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['followersListData']);
        queryClient.invalidateQueries(['otherProfileUserData']);
        queryClient.invalidateQueries(['userProfilePageData']);
        queryClient.invalidateQueries(['otherProfileBusinessData']);
      },
      onError: (error) => { },
    }
  );

  //navigate to profile
  const navigate = useNavigate();
  const handleNavigationToProfile = () => {
    props.onClose();
    // navigate(PATH_USER.userProfile, { state: { otherUserId: toUser } });
    navigate(PATH_USER.userProfile, { state: { otherUserId: fromUser } });
  };
  return (
    <ListItem className="px-0 py-3">
      <ListItemAvatar
        className="linkList_avatar_container"
        onClick={handleNavigationToProfile}
      >
        <Avatar>
          {permissions?.visibility?.picture ? (
            <UserAvatar
              diameter={40}
              imgSrc={files(userPic, 'image')}
              alternateSrc={Array.from(fullName)[0]}
            />
          ) : (
            <UserAvatar
              diameter={40}
              imgSrc={''}
              alternateSrc={Array.from(fullName)[0]}
            />
          )}
        </Avatar>
      </ListItemAvatar>

      <div className="d-flex flex-column" onClick={handleNavigationToProfile}>
        <h4 className="app_text_14_500">{fullName}</h4>
        <div className="d-flex">
          {(userName || fullName) && (
            <span className="app_text_gray app_text_12">
              {`@${userName ? userName : fullName}`}{' '}
            </span>
          )}
        </div>
      </div>
      {fromUser === profileId ? (
        <Button
          disabled
          className="ms-auto app_shodow_btn app_bg_primary_light app_text_12_semibold app_text_transform app_text_primary"
          variant="contained"
          sx={{
            maxWidth: '85px',
            width: '100%',
            textTransform: 'capitalize',
            borderRadius: '8px',
          }}
        >
          {props.t('popup_btn.you')}
        </Button>
      ) : status === 1 ? (
        <Button
          className="ms-auto app_text_transform app_shodow_btn app_bg_primary_medium app_text_12_semibold  app_text_primary"
          variant="contained"
          sx={{
            maxWidth: '85px',
            width: '100%',
            textTransform: 'capitalize',
            borderRadius: '8px',
          }}
          onClick={() =>
            userFollowClick({ toUserfollow: fromUser, isBusiness: 0 })
          }
        >
          {props.t('popup_btn.following')}
        </Button>
      ) : (
        <Button
          className="ms-auto app_shodow_btn app_bg_primary app_text_12_semibold app_text_transform"
          variant="contained"
          sx={{
            maxWidth: '85px',
            width: '100%',
            textTransform: 'capitalize',
            borderRadius: '8px',
            color: '#fff !important',
          }}
          onClick={() =>
            userFollowClick({ toUserfollow: fromUser, isBusiness: 0 })
          }
        >
          {props.t('popup_btn.follow')}
        </Button>
      )}
    </ListItem>
  );
};

export default withTranslation()(FollowersRow);
