import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProfileSettingIcon from 'src/svgComponents/sidebarIcons_updated/ProfileSettingIcon';
import IdVerifyIcon from 'src/svgComponents/sidebarIcons_updated/IdVerifyIcon';
import Permissions from 'src/svgComponents/sidebarIcons_updated/Permissions';
import GeneralSetting from 'src/svgComponents/sidebarIcons_updated/GeneralSetting';
import ReportProblemIcon from 'src/svgComponents/sidebarIcons_updated/ReportProblemIcon';
import EmployeesIcon from 'src/svgComponents/sidebarIcons_updated/EmployeesIcon';
import BusinessAddressesIcon from 'src/svgComponents/sidebarIcons_updated/BusinessAddressesIcon';
import CreateBusinessIcon from 'src/svgComponents/sidebarIcons_updated/CreateBusinessIcon';
import { PATH_CHAT, PATH_CHATVOL2, PATH_DASHBOARD } from 'src/routes/paths';
import { UserRole } from 'src/utils/enum';
import { authStore } from 'src/contexts/AuthProvider';
import { Backdrop, Box, Fab, List, ListItemButton } from '@mui/material';
import { useState } from 'react';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { withTranslation } from 'react-i18next';
import RequestsIcon from 'src/svgComponents/sidebarIcons_updated/RequestsIcon';
import MyContactIcon from 'src/svgComponents/sidebarIcons_updated/MyContactIcon';
import ChatIcon from 'src/svgComponents/sidebarIcons_updated/ChatIcon';
import TrackTraceIcon from 'src/svgComponents/sidebarIcons_updated/TrackTraceIcon';
import SocialsIcon from 'src/svgComponents/sidebarIcons_updated/SocialsIcon';
import EventsIcon from 'src/svgComponents/sidebarIcons_updated/EventsIcon';
import FastMarketingIcon from 'src/svgComponents/sidebarIcons_updated/FastMarketingIcon';
import NotificationIcon from 'src/svgComponents/sidebarIcons_updated/NotificationIcon';
import AppSettingIcon from 'src/svgComponents/sidebarIcons_updated/AppSettingIcon';
import LocationLogIcon from 'src/svgComponents/sidebarIcons_updated/LocationLogIcon';
import BusinessContactIcon from 'src/svgComponents/sidebarIcons_updated/BusinessContactIcon';
import DeleteBusinessIcon from 'src/svgComponents/sidebarIcons_updated/DeleteBusinessIcon';
import ContactUs from 'src/svgComponents/sidebarIcons_updated/ContactUs';
import EmpLeaveIcon from 'src/svgComponents/EmpLeaveIcon';

const DashboardSidebar = (props) => {
  const { onClose } = props;

  const sidebarData = [
    {
      linkText: props.t('sidebar_menu_text.profile_setting'),
      link: PATH_DASHBOARD.general.profileSetting,
      linkIconElem: <ProfileSettingIcon />,
      permission: [UserRole.USER, UserRole.GUEST],
    },
    {
      linkText: props.t('sidebar_menu_text.create_business'),
      link: PATH_DASHBOARD.general.createBusiness,
      linkIconElem: <CreateBusinessIcon />,
      permission: [UserRole.USER, UserRole.GUEST],
      canRemove: true,
    },
    {
      linkText: props.t('sidebar_menu_text.trust_setting'),
      link: PATH_DASHBOARD.general.trustSetting,
      linkIconElem: <IdVerifyIcon />,
      permission: [UserRole.USER, UserRole.GUEST],
    },
    {
      linkText: props.t('sidebar_menu_text.permissions'),
      link: PATH_DASHBOARD.general.permission,
      linkIconElem: <Permissions />,
      permission: [UserRole.USER, UserRole.GUEST],
    },
    {
      linkText: props.t('sidebar_menu_text.requests'),
      link: PATH_DASHBOARD.general.requests,
      linkIconElem: <RequestsIcon />,
      permission: [UserRole.USER, UserRole.GUEST],
    },
    {
      linkText: props.t('sidebar_menu_text.my_contact'),
      link: PATH_DASHBOARD.general.myContacts,
      linkIconElem: <MyContactIcon />,
      permission: [UserRole.USER, UserRole.GUEST],
    },
    {
      linkText: props.t('sidebar_menu_text.chats'),
      // link: PATH_CHAT.root,
      link: PATH_CHATVOL2.root,
      linkIconElem: <ChatIcon />,
      permission: [UserRole.USER, UserRole.GUEST],
    },
    {
      linkText: props.t('sidebar_menu_text.location_log'),
      link: PATH_DASHBOARD.general.locationLog,
      linkIconElem: <LocationLogIcon />,
      permission: [UserRole.USER, UserRole.GUEST],
    },
    {
      linkText: props.t('sidebar_menu_text.track_trace'),
      link: PATH_DASHBOARD.general.trackTrace,
      linkIconElem: <TrackTraceIcon />,
      permission: [UserRole.USER, UserRole.GUEST],
    },
    {
      linkText: props.t('sidebar_menu_text.socials'),
      link: PATH_DASHBOARD.general.social,
      linkIconElem: <SocialsIcon />,
      permission: [UserRole.USER, UserRole.GUEST],
    },

    {
      linkText: props.t('sidebar_menu_text.events'),
      link: PATH_DASHBOARD.general.events,
      linkIconElem: <EventsIcon />,
      permission: [UserRole.USER, UserRole.GUEST],
    },
    {
      linkText: props.t('sidebar_menu_text.fast_marketing'),
      link: PATH_DASHBOARD.comming,
      linkIconElem: <FastMarketingIcon />,
      permission: [UserRole.USER, UserRole.GUEST],
    },
    {
      linkText: props.t('sidebar_menu_text.report_issue'),
      link: PATH_DASHBOARD.general.report,
      linkIconElem: <ReportProblemIcon />,
      permission: [UserRole.USER, UserRole.GUEST],
    },
    {
      linkText: props.t('sidebar_menu_text.notification'),
      link: PATH_DASHBOARD.general.notification,
      linkIconElem: <NotificationIcon />,
      permission: [UserRole.USER, UserRole.GUEST],
    },
    {
      linkText: props.t('sidebar_menu_text.app_setting'),
      link: PATH_DASHBOARD.general.appSetting,
      linkIconElem: <AppSettingIcon />,
      permission: [UserRole.USER, UserRole.GUEST],
    },
    {
      linkText: props.t('sidebar_menu_text.general_settings'),
      link: PATH_DASHBOARD.general.general,
      linkIconElem: <GeneralSetting />,
      permission: [UserRole.USER, UserRole.GUEST],
    },
    {
      linkText: props.t('sidebar_menu_text.contact_us'),
      link: PATH_DASHBOARD.general.contactUs,
      linkIconElem: <ContactUs />,
      permission: [UserRole.USER, UserRole.GUEST],
    },

    {
      linkText: props.t('sidebar_menu_text.business_profile_setting'),
      link: PATH_DASHBOARD.general.businessProfileSetting,
      linkIconElem: <ProfileSettingIcon />,
      permission: [UserRole.BUSINESS],
    },
    {
      linkText: props.t('sidebar_menu_text.business_locations'),
      link: PATH_DASHBOARD.general.businessAddress,
      linkIconElem: <BusinessAddressesIcon />,
      permission: [UserRole.BUSINESS],
    },
    {
      linkText: props.t('sidebar_menu_text.business_document'),
      link: PATH_DASHBOARD.general.businessIdVerify,
      linkIconElem: <IdVerifyIcon />,
      permission: [UserRole.BUSINESS],
    },
    {
      linkText: props.t('sidebar_menu_text.business_permissions'),
      link: PATH_DASHBOARD.general.businessPermission,
      linkIconElem: <Permissions />,
      permission: [UserRole.BUSINESS],
    },
    {
      linkText: props.t('sidebar_menu_text.business_contact'),
      link: PATH_DASHBOARD.general.businessContact,
      linkIconElem: <BusinessContactIcon />,
      permission: [UserRole.BUSINESS],
    },
    {
      linkText: props.t('sidebar_menu_text.business_employees'),
      link: PATH_DASHBOARD.general.employee,
      linkIconElem: <EmployeesIcon />,
      permission: [UserRole.BUSINESS],
    },
    {
      linkText: props.t('sidebar_menu_text.employee_availability'),
      link: PATH_DASHBOARD.general.businessEmpAvailability,
      linkIconElem: <EmpLeaveIcon />,
      permission: [UserRole.BUSINESS],
    },
    {
      linkText: props.t('sidebar_menu_text.chats'),
      // link: PATH_CHAT.root,
      link: PATH_CHATVOL2.root,
      linkIconElem: <ChatIcon />,
      permission: [UserRole.BUSINESS],
    },
    {
      linkText: props.t('sidebar_menu_text.socials'),
      link: PATH_DASHBOARD.general.social,
      linkIconElem: <SocialsIcon />,
      permission: [UserRole.BUSINESS],
    },
    {
      linkText: props.t('sidebar_menu_text.events'),
      link: PATH_DASHBOARD.general.events,
      linkIconElem: <EventsIcon />,
      permission: [UserRole.BUSINESS],
    },
    {
      linkText: props.t('sidebar_menu_text.fast_marketing'),
      link: PATH_DASHBOARD.comming,
      linkIconElem: <FastMarketingIcon />,
      permission: [UserRole.BUSINESS],
    },
    {
      linkText: props.t('sidebar_menu_text.report_issue'),
      link: PATH_DASHBOARD.general.report,
      linkIconElem: <ReportProblemIcon />,
      permission: [UserRole.BUSINESS],
    },
    // {
    //   linkText: props.t("sidebar_menu_text.notification"),
    //   link: PATH_DASHBOARD.comming,
    //   linkIconElem: <NotificationIcon />,
    //   permission: [UserRole.BUSINESS],
    // },
    {
      linkText: props.t('sidebar_menu_text.delete_business'),
      link: PATH_DASHBOARD.general.businessSetting,
      linkIconElem: <DeleteBusinessIcon />,
      permission: [UserRole.BUSINESS],
    },
    // {
    //   linkText: props.t("sidebar_menu_text.contact_us"),
    //   link: PATH_DASHBOARD.general.contactUs,
    //   linkIconElem: <ContactUs />,
    //   // permission: [UserRole.BUSINESS],
    // },
  ];

  // const axiosPrivate = useAxiosPrivate();
  // const { data: userProfileSidebarData } = useQuery(
  //   'userProfileSidebar',
  //   async ({ signal }) => {
  //     return await axiosPrivate
  //       .get(INDIVIDUAL_API_URL.profile, { signal })
  //       .then((res) => res.data);
  //   },
  //   { refetchOnWindowFocus: false }
  // );
  // const userVerifyData = userProfileSidebarData?.trustDetails;

  const [showDashboard, setShowDashboard] = useState(false);
  const [isOpenedManually, setIsOpenedMenaually] = useState(false);
  // const [sideBarDataState, setSideBarDataState] = useState(sidebarData);

  const location = useLocation();
  let navigate = useNavigate(null);
  let activeLink = location.pathname;
  const role = authStore((state) => state.role);
  const setIsGuestContinue = authStore((state) => state.setIsGuestContinue);
  const hasBusiness = authStore((state) => state.hasBusiness);
  const handleLinkClick = (ev, path) => {
    if (role === UserRole.GUEST) {
      setIsGuestContinue(true);
      ev.preventDefault();
      return false;
    }
    navigate(path);
    setShowDashboard(false);
    setIsOpenedMenaually(false);
    return true;
  };

  // useEffect(() => {
  //   setSideBarDataState(sidebarData)
  // },[sidebarData]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      const windowCurrentWidth = window.outerWidth;
      if (windowCurrentWidth < 991 && activeLink.includes('/dashboard')) {
        setShowDashboard(false);
        setIsOpenedMenaually(false);
      }
    });
    return window.removeEventListener('resize', () => {
      const windowCurrentWidth = window.outerWidth;
      if (windowCurrentWidth < 991 && activeLink.includes('/dashboard')) {
        setShowDashboard(false);
        setIsOpenedMenaually(false);
      }
    });
  });
  const handleSidebarOnManualOpen = () => {
    setIsOpenedMenaually(true);
    setShowDashboard(true);
  };

  const handleDashboardBackdropClose = () => {
    setShowDashboard(false);
    setIsOpenedMenaually(false);
  };
  // useEffect(() => {
  //   if (hasBusiness) {
  //     const check = _.filter(sidebarData, (o) => !o?.canRemove);
  //     setSideBarDataState(check);
  //   } else {
  //     setSideBarDataState(sidebarData);
  //   }
  // }, [hasBusiness]);

  // useEffect(() => {
  //   if (userVerifyData) {
  //     setSideBarDataState((prev) => {
  //       return prev.map((item) => {
  //         if (item.hasOwnProperty("idProfileStatus")) {
  //           item.idProfileStatus = userVerifyData?.id;
  //         } else if (item.hasOwnProperty("selfieProfileStatus")) {
  //           item.selfieProfileStatus = userVerifyData?.image;
  //         } else if (item.hasOwnProperty("referenceProfileStatus")) {
  //           item.referenceProfileStatus = userVerifyData?.reference;
  //         } else if (item.hasOwnProperty("addressProfileStatus")) {
  //           item.addressProfileStatus = userVerifyData?.address;
  //         } else {
  //           return item;
  //         }
  //         return item;
  //       });
  //     });
  //   }
  // }, [userVerifyData]);

  return (
    <>
      <Box
        className={`${activeLink.includes('home')
          ? 'main_sidebar_wrapper_map'
          : 'main_sidebar_wrapper'
          } ${showDashboard ? 'show' : ''}`}
      >
        <List disablePadding>
          {sidebarData
            .filter((item) => {
              if (hasBusiness) {
                return !item.canRemove;
              } else {
                return item;
              }
            })
            .filter(
              (item_) => !item_.permission || item_.permission.includes(role)
            )
            .map((item, index) => (
              <ListItemButton
                onClick={(event) => handleLinkClick(event, item.link)}
                key={index}
                className={`main_sidebar_item ${activeLink === item.link ? 'isActive' : ''
                  }`}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    width: '100%',
                  }}
                >
                  <div
                    style={{ flex: 1, display: 'flex', alignItems: 'center' }}
                  >
                    {item.linkIconElem}
                    <span className="main_sidebar_link_text">
                      {item.linkText}
                    </span>
                  </div>
                </Box>
              </ListItemButton>
            ))}
        </List>
      </Box>
      {isOpenedManually && (
        <Backdrop
          className="dashbord_backdrop"
          sx={{ color: '#fff' }}
          open={isOpenedManually}
          onClick={handleDashboardBackdropClose}
        ></Backdrop>
      )}

      {!showDashboard && (
        <Fab
          aria-label="add"
          className="dashboard_sidebar_button"
          onClick={() => handleSidebarOnManualOpen()}
        >
          <WidgetsIcon className="sidebar_opan_icon" />
        </Fab>
      )}
    </>
  );
};

export default withTranslation()(DashboardSidebar);
