// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_GUEST = '/home';
const ROOTS_CHAT = '/chat';
const ROOTS_CHATVOL2 = '/chat-vol2';
const ROOTS_USER = '/user';
const ROOTS_BUSINESS = '/business';
const COMMING_SOON = '/comming-soon';
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  privacyPolicy: path(ROOTS_AUTH,'privacy-policy'),
  businessCildPolicy: path(ROOTS_AUTH,'business-child-policy'),
  login: path(ROOTS_AUTH, 'login'),
  register: path(ROOTS_AUTH, 'register'),
  verification: path(ROOTS_AUTH, 'verification'),
  resetPassword: path(ROOTS_AUTH, 'reset-password'),
  homeContactUs: path(ROOTS_AUTH, 'home/contact-us'),
};

export const PATH_GUEST = {
  root: ROOTS_GUEST,
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  comming: COMMING_SOON,
  general: {
    profileSetting: path(ROOTS_DASHBOARD, '/profile-settings'),
    trustSetting: path(ROOTS_DASHBOARD, '/trust-settings'),
    idVerify: path(ROOTS_DASHBOARD, '/id-verify'),
    selfiVerify: path(ROOTS_DASHBOARD, '/selfi-verify'),
    permission: path(ROOTS_DASHBOARD, '/permission'),
    reference: path(ROOTS_DASHBOARD, '/reference'),
    requests: path(ROOTS_DASHBOARD, '/requests'),
    myContacts: path(ROOTS_DASHBOARD, '/my-contacts'),
    trackTrace: path(ROOTS_DASHBOARD, '/track-trace'),

    appSetting: path(ROOTS_DASHBOARD, '/app-setting'),
    homeAddress: path(ROOTS_DASHBOARD, '/address'),
    createBusiness: path(ROOTS_DASHBOARD, '/create-business'),
    businessProfileSetting: path(ROOTS_DASHBOARD, '/business-profile'),
    general: path(ROOTS_DASHBOARD, '/general-settings'),
    ads: path(ROOTS_DASHBOARD, '/ads'),
    report: path(ROOTS_DASHBOARD, '/report'),
    createEvent: path(ROOTS_DASHBOARD, '/events/create-event'),
    createEventConfirmation: path(ROOTS_DASHBOARD, '/events/event-confirm'),
    cancelEventConfirmation: path(
      ROOTS_DASHBOARD,
      '/events/event-cancel-confirm'
    ),
    events: path(ROOTS_DASHBOARD, '/events'),
    social: path(ROOTS_DASHBOARD, '/social'),
    notification: path(ROOTS_DASHBOARD, '/notification'),
    eventdetail: path(ROOTS_DASHBOARD, '/events/event-detail'),
    profile: path(ROOTS_DASHBOARD, '/profile'),

    employee: path(ROOTS_DASHBOARD, '/employee'),
    businessAddress: path(ROOTS_DASHBOARD, '/business-address'),
    businessIdVerify: path(ROOTS_DASHBOARD, '/business-id-verify'),
    businessPermission: path(ROOTS_DASHBOARD, '/business-permission'),
    businessContact: path(ROOTS_DASHBOARD, '/business-contact'),
    businessRequest: path(ROOTS_DASHBOARD, '/business-request'),
    businessSetting: path(ROOTS_DASHBOARD, '/business-setting'),
    employeeRequest: path(ROOTS_DASHBOARD, '/employee-request'),
    employeeAvailability: path(ROOTS_DASHBOARD, '/employee-availability'),
    locationLog: path(ROOTS_DASHBOARD, '/location-log'),
    contactUs: path(ROOTS_DASHBOARD, '/contact-us'),

    empLeaveRequest: path(ROOTS_DASHBOARD, '/employee-leave-request'),
    businessEmpAvailability: path(ROOTS_DASHBOARD, '/employess-availability'),
    feedDetail: path(ROOTS_DASHBOARD, '/feed-detail'),
    setPermission: path(ROOTS_DASHBOARD, '/set-permission'),
  },
};
export const PATH_CHAT = {
  root: ROOTS_CHAT,
  chatId: path(ROOTS_CHAT, '/chat/:id'),
};
export const PATH_CHATVOL2 = {
  root: ROOTS_CHATVOL2,
  chatId: path(ROOTS_CHATVOL2, '/chat/:id'),
};
export const PATH_HOME = {
  root: ROOTS_AUTH,
};
export const PATH_HOME_ALT = {
  root: ROOTS_GUEST,
};
export const PATH_USER = {
  root: ROOTS_USER,
  userProfile: path(ROOTS_USER, '/profile'),
  businessProfile: path(ROOTS_BUSINESS, '/profile'),
};

export const PATH_BUSINESS = {
  root: ROOTS_USER,
  businessProfile: path(ROOTS_BUSINESS, '/profile'),
};
