import { createSlice } from "@reduxjs/toolkit";
// import { IAppReducer } from "../../../utils/types";

// user reducer store user data and all types of token
const initialState = {
  isSkip: false,
  configurableFieldData: null,
  businessProfileData: null,
  conversationList: [],
  selectedConversation:null
};

const AppReducer = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsSkip: (state, action) => {
      state.isSkip = action.payload;
    },
    setConfigurableFieldData: (state, action) => {
      state.configurableFieldData = action.payload;
    },
    setBusinessProfileData: (state, action) => {
      state.businessProfileData = action.payload;
    },
    setConversationList: (state, action) => {
      state.conversationList = action.payload;
    },
    setSelectedConversationData: (state, action) => {
      console.log('setSelectedConversationData',action);
      
      state.selectedConversation = action.payload;
    },
  },
});

export const {
  setIsSkip,
  setConfigurableFieldData,
  setBusinessProfileData,
  setConversationList,
  setSelectedConversationData
} = AppReducer.actions;

export default AppReducer.reducer;
