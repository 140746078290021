import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';

import { withTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import UserAvatar from '../common/UserAvatar';
import LocationPinCircleBase from 'src/svgComponents/LocationPinCircleBase';
import TrustLevelReadOnly from '../common/TrustLevelReadOnly';
import files from 'src/helpers/helpers';
import { authStore } from 'src/contexts/AuthProvider';
import { PATH_CHAT, PATH_USER } from 'src/routes/paths';
import { UserRole } from 'src/utils/enum';
import { useNavigate } from 'react-router';
import '@lottiefiles/lottie-player';
import _ from 'lodash';
import LocationIconCommon from 'src/svgComponents/LocationIconCommon';
import defaultBannerImage from 'src/assets/images/user_placeholder.png';
import LinkingPopup from '../user/LinkingPopup';
import FollowersPopup from '../user/FollowersPopup';
import FollowingPopup from '../user/FollowingPopup';
import { useDispatch, useSelector } from 'react-redux';
import CompleteVerificationDialog from 'src/pages/home/CompleteVerificationDialog';
import VerifyOTPDialog from 'src/pages/dialog/VerifyOTPDialog';
import { saveUserData } from 'src/redux/reducers/user/UserReducer';
import { useQuery } from 'react-query';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import { INDIVIDUAL_API_URL } from 'src/api/axios';

const MapSidebarViewProfile = (props) => {
  const {
    onCloseFunc,
    lottiPlayerLoder,
    otherBusinessAddressId,
    otherUserData,
    otherBusinessData,
    businessAddress,
    businessEmployee,
    businessStatus,
    t,
    groupStatus,
    groupData,
  } = props;
  const mapViewProfileContentBoxRef = useRef(null);
  const [mapViewProfileHeaderClassName, setMapViewProfileHeaderClassName] =
    useState('');
  const { _id: profileId } = authStore((state) => state.user);
  useEffect(() => {
    const scrollEvent = () => {
      if (mapViewProfileContentBoxRef.current.scrollTop > 5) {
        setMapViewProfileHeaderClassName('scrolling');
      } else {
        setMapViewProfileHeaderClassName('');
      }
    };
    const scrollConst = mapViewProfileContentBoxRef?.current;
    scrollConst.addEventListener('scroll', scrollEvent);
    return () => {
      scrollConst.removeEventListener('scroll', scrollEvent);
    };
  }, []);
  return (
    <div className="map_viewProfile_wrapper">
      <ProfileHeader
        className={mapViewProfileHeaderClassName}
        onCloseFunc={onCloseFunc}
        profileTitle={
          businessStatus
            ? otherBusinessData?.name
            : groupStatus
              ? groupData?.name
              : otherUserData?.fullName
        }
      />
      <Box ref={mapViewProfileContentBoxRef} className="map_profile_content">
        {lottiPlayerLoder ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 400 }}
          >
            <lottie-player
              autoplay
              loop
              mode="normal"
              src="https://assets6.lottiefiles.com/packages/lf20_f1dhzsnx.json"
            ></lottie-player>
          </Stack>
        ) : (
          <>
            <ProfileUserInfo
              userImage={
                businessStatus
                  ? otherBusinessData?.image
                  : groupStatus
                    ? groupData?.image
                    : otherUserData?.profilePic
              }
              fullName={
                businessStatus
                  ? otherBusinessData?.name
                  : groupStatus
                    ? groupData?.name
                    : otherUserData?.fullName
              }
              status={
                businessStatus
                  ? otherBusinessData?.businessStatus
                  : otherUserData?.userStatus
              }
              location={
                businessStatus
                  ? otherBusinessData?.address?.physicalAddress
                  : otherUserData?.address
              }
              trustRating={otherUserData?.trust}
              // linkedCount={businessStatus ? otherBusinessData?.linkedUserCount : otherUserData?.linkedUserCount}
              followerUserCount={
                businessStatus
                  ? otherBusinessData?.followerBusinessCount
                  : otherUserData?.followerUserCount
              }
              followingUserCount={otherUserData?.followingUserCount}
              eventCount={otherUserData?.eventsCount}
              postCount={otherUserData?.postCount}
              userBio={
                businessStatus
                  ? otherBusinessData?.bio
                  : groupStatus
                    ? groupData?.description
                    : otherUserData?.bio
              }
              permissionVisibility={otherUserData?.visibility}
              groupCreatedBy={groupData?.createdBy?.name}
              groupMember={groupData?.members}
              groupAdmins={groupData?.admins}
              groupData={groupData}
              profileId={profileId}
              businessStatus={businessStatus}
              businessEmployee={businessEmployee}
              chatPermission={otherUserData?.chatPermission}
              businessAddress={businessAddress}
              bannerImage={otherUserData?.bannerImage}
              userPhotos={otherUserData?.photos}
              groupStatus={groupStatus}
              userId={otherUserData?.userId}
              businessId={otherBusinessData?.id}
              userEmployee={otherUserData?.employee}
              contact={otherUserData?.contact}
              otherBusinessAddressId={otherBusinessAddressId}
              t={t}
            />
          </>
        )}
      </Box>
    </div>
  );
};
export default withTranslation()(MapSidebarViewProfile);

const ProfileHeader = ({ className, onCloseFunc, profileTitle }) => {
  return (
    <Box
      className={`map_viewProfile_header ${className}`}
      sx={{ bgcolor: '#6200ee' }}
    >
      <h4 className="app_text_16_semibold mx-auto text-center pt-1 w-100 mb-0 mt-1 text_limit_250 app_text_transform title text-white">
        {profileTitle ? profileTitle : 'Profile'}
      </h4>
      <IconButton onClick={() => onCloseFunc()} className="close_btn">
        <Close style={{ color: '#fff' }} />
      </IconButton>
    </Box>
  );
};

const ProfileUserInfo = ({
  groupData,
  otherBusinessAddressId,
  followerUserCount,
  followingUserCount,
  userId,
  businessId,
  userPhotos,
  bannerImage,
  t,
  fullName,
  chatPermission,
  businessEmployee,
  businessAddress,
  groupMember,
  profileId,
  groupAdmins,
  groupCreatedBy,
  groupStatus,
  businessStatus,
  permissionVisibility,
  userBio,
  userImage,
  linkedCount,
  eventCount,
  postCount,
  trustRating,
  location,
  status,
}) => {
  const [imageUrl, setImageUrl] = useState('');
  const role = authStore((state) => state.role);
  const setIsGuestContinue = authStore((state) => state.setIsGuestContinue);
  const [partOfGroup, setPartOfGroup] = useState(false);
  const [openLinkPopup, setOpenLinkPopup] = useState(false);
  const [openFollowersPopup, setOpenFollowersPopup] = useState(false);
  const [openFollowingPopup, setOpenFollowingPopup] = useState(false);
  const [requestId, setRequestId] = useState();
  const navigate = useNavigate();
  
  const userDataProfile = useSelector((state) => state.user.userData);

  const handleLinkClick = (ev) => {
    if (role === UserRole.GUEST) {
      setIsGuestContinue(true);
      ev.preventDefault();
      return false;
    }
  };
  useEffect(() => {
    if (businessStatus || groupStatus) {
      setImageUrl(files(userImage, 'image'));
    } else {
      setImageUrl(
        permissionVisibility?.picture && userImage
          ? files(userImage, 'image')
          : ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userImage, businessStatus, groupStatus]);

  useEffect(() => {
    if (!_.isEmpty(groupMember) || !_.isEmpty(groupAdmins)) {
      if ([...groupMember, ...groupAdmins].find((k) => k._id === profileId)) {
        setPartOfGroup(true);
      } else {
        setPartOfGroup(false);
      }
    }
  }, [groupMember, groupAdmins, profileId]);

  const BannerImage = ({ status, bannerImages, userImages }) => {
    const [imageError, setImageError] = useState(false);
    switch (status) {
      case 1:
      case 2:
        return (
          <img
            src={!imageError ? files(userImages, 'image') : defaultBannerImage}
            onError={() => setImageError(true)}
            alt=""
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        );

      case 3:
        return (
          <img
            src={
              !imageError ? files(bannerImages, 'image') : defaultBannerImage
            }
            onError={() => setImageError(true)}
            alt=""
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        );

      default:
        return (
          <img
            src={defaultBannerImage}
            alt=""
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        );
    }
  };

  const handleClickLinkPopupOpen = (dataId) => {
    setOpenLinkPopup(true);
    setRequestId(dataId);
  };

  const handleLinkPopupClose = (value) => {
    setOpenLinkPopup(false);
    setRequestId();
  };

  const handleClickFollowersPopupOpen = (dataId) => {
    setOpenFollowersPopup(true);
    setRequestId(dataId);
  };

  const handleFollowersPopupClose = (value) => {
    setOpenFollowersPopup(false);
    setRequestId();
  };

  const handleClickFollowingPopupOpen = (dataId) => {
    setOpenFollowingPopup(true);
    setRequestId(dataId);
  };

  const handleFollowingPopupClose = (value) => {
    setOpenFollowingPopup(false);
    setRequestId();
  };
  const otheruserProfileClick = (businessStatus) => {
    if (businessStatus) {
      navigate(PATH_USER.businessProfile, {
        state: {
          otherUserBusinessId: businessId,
          otherBusinessAddressId: otherBusinessAddressId,
        },
      });
    } else {
      navigate(PATH_USER.userProfile, { state: { otherUserId: userId } });
    }
  };

  const axiosPrivate = useAxiosPrivate();

  const [isVerifyMessageDialog, setIsVerifyMessageDiloag] = useState(false)
  const [verifyDialogOpen, setVerifyDialogOpen] = useState(false)

  const dispatch = useDispatch();
 
  const { refetch: userProfileFatch } = useQuery(
    'userProfileSetting',
    async ({ signal }) => {
      return await axiosPrivate
        .get(INDIVIDUAL_API_URL.profile, { signal })
        .then((res) => res.data);
    },
    {
      enabled: false,
      refetchOnWindowFocus: false, onSuccess: (data) => {
        dispatch(saveUserData(data));
      }
    }
  );


  return (
    <>
      <Box>
        <Box
          className="profile_banner"
          sx={{ width: '100%', height: '160px', position: 'relative' }}
        >
          <BannerImage
            status={businessStatus ? 1 : groupStatus ? 2 : 3}
            userImages={userImage}
            bannerImages={bannerImage}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: '-45px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: 'calc(100% - 30px)',
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
            }}
            className="profile_image_wrapper_avatar"
          >
            <UserAvatar
              diameter={100}
              alternateSrc={fullName}
              imgSrc={imageUrl}
            />
            <Box className="profile_popup_followers_details">
              {!groupStatus && (
                <Button
                  sx={{
                    textTransform: 'lowercase',
                    minWidth: 'unset',
                    padding: '0px',
                    fontSize: 'unset',
                    fontWeight: 'unset',
                  }}
                  onClick={() =>
                    handleClickFollowersPopupOpen(
                      businessStatus ? businessId : userId
                    )
                  }
                >
                  <Box className="text-content text-center">
                    <Typography
                      component={'span'}
                      className="app_text_16_semibold app_text_black_2"
                    >
                      {followerUserCount ?? 0}
                    </Typography>
                    <Typography component={'p'} className="app_text_14">
                      {t('comman_label.followers')}
                    </Typography>
                  </Box>
                </Button>
              )}
              {!groupStatus && !businessStatus && (
                <Button
                  sx={{
                    textTransform: 'lowercase',
                    minWidth: 'unset',
                    padding: '0px',
                    fontSize: 'unset',
                    fontWeight: 'unset',
                  }}
                  onClick={() =>
                    handleClickFollowingPopupOpen(
                      businessStatus ? businessId : userId
                    )
                  }
                >
                  <Box className="text-content text-center">
                    <Typography
                      component={'span'}
                      className="app_text_16_semibold app_text_black_2"
                    >
                      {followingUserCount ?? 0}
                    </Typography>
                    <Typography component={'p'} className="app_text_14">
                      {t('comman_label.following')}
                    </Typography>
                  </Box>
                </Button>
              )}
              {/* {!groupStatus &&
            // <Button sx={{textTransform:"lowercase",minWidth:"unset", padding:"0px",fontSize:"unset",fontWeight:"unset"}} onClick={() => handleClickLinkPopupOpen(businessStatus ? businessId : userId )}>
            //   <Box className="text-content text-center">
            //     <Typography component={"span"} className="app_text_16_semibold app_text_black_2">{linkedCount ?? 0}</Typography>
            //     <Typography component={"p"} className="app_text_14">{t('comman_label.linked')}</Typography>
            //   </Box>
            // </Button>
            } */}
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: '60px', padding: '0px 20px' }}>
          <Box sx={{ width: '100%', height: 'auto', marginBottom: '24px' }}>
            <Box className="row h-100  align-items-end">
              <Box className="col-6">
                <Box sx={{ width: '100%' }}>
                  <Typography
                    component={'span'}
                    className="app_text_18_500 d-block"
                  >
                    {fullName}
                  </Typography>
                  {groupStatus && groupCreatedBy ? (
                    <Typography
                      component={'span'}
                      className="app_text_14 app_text_gray"
                    >
                      {t('comman_label.created_by')} {groupCreatedBy}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  {groupStatus ? (
                    <></>
                  ) : (permissionVisibility?.status && status) ||
                    businessStatus ? (
                    <Typography
                      component={'span'}
                      className="app_text_14 app_text_gray"
                    >
                      {status}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  {location && (
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                    >
                      <Typography
                        component={'span'}
                        className="app_text_14_500 mb-1"
                      >
                        <LocationIconCommon /> {location}
                      </Typography>
                    </Box>
                  )}
                  {!groupStatus && !businessStatus && trustRating ? (
                    <TrustLevelReadOnly rating={trustRating} maxStar={5} />
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
              {/* {!groupStatus &&
              <Box className="col-6">
                <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                  <Button
                    variant="contained"
                    className="user_profile_list_btn ms-2"
                  >
                    <ChatFilled color={"#6200EE"} size={24} />
                  </Button>
                  <Button
                    variant="contained"
                    className="user_profile_list_btn ms-2"
                  >
                    <DirectionsIcon
                      style={{ color: "#6200EE", fontSize: "24px" }}
                    />
                  </Button>
                  <Button
                    variant="contained"
                    className="user_profile_list_btn ms-2"
                  >
                    <LocalTaxiIcon
                      style={{ color: "#6200EE", fontSize: "24px" }}
                    />
                  </Button>
                </Box>
              </Box>
            } */}
            </Box>
          </Box>
          {/* {!groupStatus &&
          <Box sx={{ width: "100%", height: "auto", marginBottom: "24px" }}>
            <Box className="row h-100 ">
              <Box className="col-6">
                <Box sx={{ width: "100%" }}>
                  <Button
                    type="submit"
                    className="app_bg_primary_light app_text_primary app_text_14_semibold"
                    variant="contained"
                    sx={{ maxWidth: "100%", width: "100%", textTransform: "capitalize", borderRadius: "8px", padding: "12px 16px" }}
                  >
                    Linked
                  </Button>
                </Box>
              </Box>
              <Box className="col-6">
                <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                  <Button
                    type="submit"
                    className="app_shodow_btn app_bg_primary app_text_14_semibold"
                    variant="contained"
                    sx={{ maxWidth: "100%", width: "100%", textTransform: "capitalize", borderRadius: "8px", color: '#fff !important', padding: "12px 16px" }}
                  >
                    follow
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        } */}
          <Box
            sx={{ width: '100%', height: 'auto', marginBottom: '24px' }}
            className="profile_photos_content_box"
          >
            {!groupStatus && !businessStatus && (
              <>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {userPhotos?.length > 0 && (
                    <Typography
                      component={'span'}
                      className="app_text_16_semibold app_text_transform"
                    >
                      {t('comman_label.photos')}
                    </Typography>
                  )}
                  {userPhotos?.length > 3 && (
                    <Typography
                      style={{ cursor: 'pointer' }}
                      component={'span'}
                      className="app_text_16_semibold app_text_transform app_text_primary"
                      onClick={() =>
                        navigate(PATH_USER.userProfile, {
                          state: { otherUserId: userId },
                        })
                      }
                    >
                      {t('comman_label.see_all')}
                    </Typography>
                  )}
                </Box>
                <Box className="photos_wrapper mt-3">
                  <Box className="row gx-2 gy-2">
                    {userPhotos?.length > 0 ? (
                      <>
                        {userPhotos.slice(0, 3).map((image, index) => (
                          <Box className="col-4" key={index}>
                            <Box
                              sx={{
                                width: '100%',
                                height: '105px',
                                borderRadius: '10px',
                                overflow: 'hidden',
                              }}
                              className="photos_wrapper"
                            >
                               {/* <Typography>{JSON.stringify(image.mage)}</Typography> */}
                              {/* {image} */}
                              <img
                                src={files(image.image, 'image')}
                                alt=""
                                style={{
                                  width: '100%',
                                  height: '105%',
                                  objectFit: 'cover',
                                  objectPosition: 'center',
                                }}
                              />
                            </Box>
                          </Box>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
              </>
            )}
            {!groupStatus && (
              <Button
                className="app_bg_primary_light app_text_primary app_text_14_semibold mt-3"
                onClick={() => otheruserProfileClick(businessStatus)}
                sx={{
                  width: '100%',
                  textTransform: 'lowercase',
                  borderRadius: '8px',
                  padding: '12px 16px',
                }}
              >
                {t('comman_label.view_more')}
              </Button>
            )}
          </Box>

          {userBio && (
            <Box sx={{ width: '100%', height: 'auto', marginBottom: '24px' }}>
              <Typography
                component={'span'}
                className="app_text_16_semibold app_text_transform"
              >
                {groupStatus
                  ? t('comman_label.group_description')
                  : t('comman_label.bio')}
              </Typography>
              <Typography
                component={'p'}
                className="app_text_14_500 app_text_transform mt-2"
              >
                {userBio}
              </Typography>
            </Box>
          )}

          {groupStatus ? (
            <Stack direction={'row'} className={'mt-4'} spacing={2}>
              <Button
                className="app_bg_primary app_text_transform app_text_14_500 text-white"
                sx={{ flexGrow: 1 }}
                onClick={(ev) =>
                  role === UserRole.GUEST
                    ? handleLinkClick(ev)
                    : !partOfGroup
                      ? navigate(`${PATH_CHAT.root}/group/${groupData._id}`, {
                        state: { isJoin: true },
                      })
                      : navigate(`${PATH_CHAT.root}/group/${groupData._id}`)
                }
              >
                {!partOfGroup
                  ? t('btn_text.join_group')
                  : t('btn_text.chat_now')}
              </Button>
            </Stack>
          ) : (
            <></>
          )}
          {businessStatus ? (
            <Box sx={{ mt: 2 }}>
              <Typography className="app_text_black app_text_14_500">
                {t('comman_label.employees')}
              </Typography>
              <Box>
                <Stack
                  direction={'row'}
                  flexWrap={'nowrap'}
                  overflow={'auto'}
                  spacing={3}
                  paddingTop={3}
                >
                  <UserAvatarWithFullnameVartical
                    size={50}
                    t={t}
                    businessEmployee={businessEmployee}
                  />
                </Stack>
              </Box>
            </Box>
          ) : (
            <></>
          )}
          {businessStatus ? (
            <Box sx={{ mt: 1 }}>
              <Typography className="app_text_black app_text_14_500">
                {t('comman_label.business_location')}
              </Typography>
              <BusinessLocationCard businessAddress={businessAddress} />
            </Box>
          ) : (
            <></>
          )}
          {groupStatus ? (
            <Box sx={{ mt: 1 }}>
              <Typography className="app_text_black app_text_14_500 text_limit_120">
                {t('comman_label.group_members')}
              </Typography>
              <Box>
                <Stack
                  direction={'row'}
                  flexWrap={'nowrap'}
                  overflow={'auto'}
                  spacing={3}
                  paddingTop={3}
                >
                  <UserAvatarWithFullnameAndRole
                    profileId={profileId}
                    groupMember={groupMember}
                    groupAdmins={groupAdmins}
                    t={t}
                    size={50}
                  />
                </Stack>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <LinkingPopup
        business={businessStatus}
        newLinkId={requestId}
        open={openLinkPopup}
        onClose={handleLinkPopupClose}
      />
      <FollowersPopup
        business={businessStatus}
        newLinkId={requestId}
        open={openFollowersPopup}
        onClose={handleFollowersPopupClose}
        userDataProfile={userDataProfile}
        openVerificationDialog={() => {
          setIsVerifyMessageDiloag(true)
        }}
      />
      <FollowingPopup
        business={businessStatus}
        newLinkId={requestId}
        open={openFollowingPopup}
        onClose={handleFollowingPopupClose}
      />

      <CompleteVerificationDialog
        open={isVerifyMessageDialog}
        onClose={() => setVerifyDialogOpen(true)}
        email={userDataProfile?.email}
        onSuccess={() => {
          setVerifyDialogOpen(true)
          setVerifyDialogOpen(true)
        }}
      />
      <VerifyOTPDialog
        open={verifyDialogOpen}
        onClose={() => setVerifyDialogOpen(false)}
        email={userDataProfile?.email}
        userId={userDataProfile?._id}
        onVerify={userProfileFatch}
      />

    </>
  );
};

const UserAvatarWithFullnameVartical = ({ businessEmployee, t, size }) => {
  return (
    <>
      {businessEmployee?.length
        ? businessEmployee.map((m, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <UserAvatar
              alternateSrc={m.fullName}
              imgSrc={
                m?.permissions?.visibility?.picture || m?.creator === 1
                  ? files(m?.image, 'image')
                  : ''
              }
              diameter={size}
            />
            <Typography className="app_text_12 app_text_gray mt-2 text-center mb-0">
              {m?.fullName}
            </Typography>
            <Typography className="app_text_12_fw500 mt-1 text-center mt-1 app_text_primary">
              {m?.designation ? m?.designation : ''}
            </Typography>
            {m?.creator === 1 ? (
              <Typography className="app_text_12_fw500 app_text_gray mt-1 text-center mt-1 app_text_primary">
                ({t('comman_label.primary')})
              </Typography>
            ) : (
              <></>
            )}
          </Box>
        ))
        : null}
    </>
  );
};

const UserAvatarWithFullnameAndRole = ({
  profileId,
  groupMember,
  groupAdmins,
  size,
  role,
  t,
}) => {
  return (
    <>
      {groupAdmins?.length
        ? groupAdmins.map((m, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <UserAvatar
              alternateSrc={m.name}
              imgSrc={files(m.image, 'image')}
              diameter={size}
            />
            <Typography className="app_text_12 app_text_gray mt-2 text-center mb-0">
              {m.name}
            </Typography>
            <Typography className="app_text_12_fw500 app_text_gray mt-1 text-center mt-1 app_text_primary">
              {t('comman_label.admin')}
            </Typography>
            {m._id === profileId && (
              <Typography className="app_text_12_fw500 app_text_gray text-center mt-1 app_text_primary">
                {t('comman_label.you')}
              </Typography>
            )}
          </Box>
        ))
        : null}
      {groupMember?.length
        ? groupMember.map((m, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <UserAvatar
              alternateSrc={m.name}
              imgSrc={files(m.image, 'image')}
              diameter={size}
            />
            <Typography className="app_text_12 app_text_gray mt-2 text-center mb-0">
              {m.name}
            </Typography>
            {m._id === profileId && (
              <Typography className="app_text_12_fw500 app_text_gray mt-1 text-center mt-1 app_text_primary">
                {t('comman_label.you')}
              </Typography>
            )}
          </Box>
        ))
        : null}
    </>
  );
};

const BusinessLocationCard = ({ businessAddress }) => {
  return (
    <>
      {businessAddress?.length
        ? businessAddress.map((m, index) => (
          <Stack
            key={index}
            spacing={2}
            direction="row"
            display={'flex'}
            alignItems="center"
            className="mt-3"
            padding={1}
            borderRadius={1.5}
            boxShadow={'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px'}
          >
            <UserAvatar
              imgSrc={files(m?.image, 'image')}
              alternateSrc={m?.businessName}
              diameter={'50'}
            />
            <Stack direction={'column'}>
              <Typography className="app_text_14_500 app_text_black">
                {m?.businessName}
              </Typography>
              <Box display={'flex'} alignItems="center">
                <LocationPinCircleBase />
                <Typography className="app_text_12 app_text_gray ms-1">
                  {m?.physicalAddress}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        ))
        : null}
    </>
  );
};
