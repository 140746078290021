import { axiosPrivate } from '../api/axios';
import { useContext, useEffect } from 'react';
import { authStore, refreshStore } from '../contexts/AuthProvider';
import { useLocation, useNavigate } from 'react-router';
import { PATH_AUTH } from 'src/routes/paths';
import { SocketContext } from 'src/contexts/socketProvide';

const useAxiosPrivate = (props) => {
  const businessId = authStore((state) => state.businessId);
  const user = authStore((state) => state.user);
  const accessToken = authStore((state) => state.accessToken);
  const refreshToken = authStore((state) => state.refreshToken);
  const logout = authStore((state) => state.logout);
  const navigate = useNavigate();
  const socket = useContext(SocketContext);
  const { pathname } = useLocation();


  

  useEffect(() => {
    // console.log("requestIntercept user", user);
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        if (props?.type === 'business' && !config.headers['businessid']) {
          config.headers['businessid'] = businessId;
        }
        if (user?._id) {
          config.headers['userid'] = user?._id;
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        // !prevRequest?.sent &&
        if (
          error?.response?.status === 401 &&
          !refreshStore.getState().isRefreshing
        ) {
          refreshStore.setState({ isRefreshing: true });
          // prevRequest.sent = true;
          const [newAccessToken, err] = await refreshToken();
          if (err) {
            if(pathname !== '/privacy-policy' && pathname !== '/terms-condition' && pathname !== '/business-child-policy' ){
              navigate(PATH_AUTH.login);
            }
            await logout();
            // Disconnecting socket connection on logout - It acknowledge to BE about the online/offline of current user
            socket?.chatClient?.disconnect();
            return Promise.reject(error);
          }
          prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          refreshStore.setState({ isRefreshing: false });
          return axiosPrivate(prevRequest);
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, accessToken, refreshToken, logout, props?.type, navigate]);

  return axiosPrivate;
};

export default useAxiosPrivate;
