import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MasterLayout from 'src/layouts/MasterLayout';
import MapLayout from 'src/layouts/MapLayout';
import HeaderLayout from 'src/layouts/HeaderLayout';
import LoadingScreen from '../components/LoadingScreen';

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

// import RoleBasedGuard from "../guards/RoleBasedGuard";
import RoleBasedGuard, {
  SingleTimeBusinessCreateGuard,
} from 'src/guards/RoleBasedGuard';
import { UserRole } from 'src/utils/enum';
import GuestGuardRole from 'src/guards/GuestGuardRole';
import EmpLeaveRequest from 'src/pages/dashboard/EmpLeaveRequest';
import BusinessEmpAvailability from 'src/pages/dashboard/BusinessEmpAvailability';
import HeaderLayoutVol2 from 'src/layouts/HeaderLayoutVol2';
//enum
// import { AdminRole, UserRole } from "src/utils/enum";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const { pathname } = useLocation();
  // const isDashboard = pathname.includes("/dashboard");
  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...{
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            },
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

// IMPORT COMPONENTS

//Home
const Home = Loadable(lazy(() => import('../pages/home/Home')));

//User Profile
const UserProfile = Loadable(lazy(() => import('../pages/User/UserProfile')));

//Social
const Social = Loadable(lazy(() => import('../pages/social/Social')));
const PostDetails = Loadable(lazy(() => import('../pages/social/PostDetails')));

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Signup = Loadable(lazy(() => import('../pages/authentication/Signup')));
const VerifyCode = Loadable(
  lazy(() => import('../pages/authentication/OTPVerification'))
);
const ForgotPassword = Loadable(
  lazy(() => import('../pages/authentication/ForgotPassword'))
);
const ResetPassword = Loadable(
  lazy(() => import('../pages/authentication/ResetPassword'))
);

// Dashboard
const ProfileSettings = Loadable(
  lazy(() => import('../pages/dashboard/ProfileSettings'))
);
const TrustSettings = Loadable(
  lazy(() => import('../pages/dashboard/TrustSettings'))
);
const IDVerification = Loadable(
  lazy(() => import('../pages/dashboard/IDVerification'))
);
const Permissions = Loadable(
  lazy(() => import('../pages/dashboard/Permissions'))
);
const SetMultiplePermission = Loadable(
  lazy(() => import('../pages/dashboard/SetMultiplePermission'))
);
const References = Loadable(
  lazy(() => import('../pages/dashboard/References'))
);
const LocationLog = Loadable(
  lazy(() => import('../pages/dashboard/LocationLog'))
);
const CreateBusiness = Loadable(
  lazy(() => import('../pages/dashboard/CreateBusiness'))
);
const BusinessProfileSetting = Loadable(
  lazy(() => import('../pages/dashboard/CreateBusiness'))
);
const BusinessIDVerification = Loadable(
  lazy(() => import('../pages/dashboard/BusinessIDVerification'))
);
const BusinessPermissions = Loadable(
  lazy(() => import('../pages/dashboard/BusinessPermissions'))
);
const BusinessAddress = Loadable(
  lazy(() => import('../pages/dashboard/BusinessAddress'))
);
const GeneralSetting = Loadable(
  lazy(() => import('../pages/dashboard/GeneralSetting'))
);
const Employee = Loadable(lazy(() => import('../pages/dashboard/Employee')));
const Chat = Loadable(lazy(() => import('../pages/chat/Chat')));
const ChatVol2 = Loadable(lazy(() => import('src/pages/chatVol2/ChatVol2')));

const HomeAddress = Loadable(
  lazy(() => import('../pages/dashboard/HomeAddress'))
);
// const SelfiVerification = Loadable(
//   lazy(() => import('../pages/dashboard/SelfiVerification'))
// );
const EmployeeRequest = Loadable(
  lazy(() => import('../pages/dashboard/EmployeeRequest'))
);
const CreateEvent = Loadable(
  lazy(() => import('../pages/dashboard/CreateEvent'))
);
const Events = Loadable(lazy(() => import('../pages/dashboard/Events')));
const CreateEventConfirmation = Loadable(
  lazy(() => import('../pages/dashboard/CreateEventConfirmation'))
);
const CancelEventConfirmation = Loadable(
  lazy(() => import('../pages/dashboard/CancelEventConfirmation'))
);
const EventDetail = Loadable(
  lazy(() => import('../pages/dashboard/EventDetail'))
);
const BusinessGeneralSetting = Loadable(
  lazy(() => import('../pages/dashboard/BusinessGeneralSetting'))
);
const ComingSoon = Loadable(
  lazy(() => import('../components/layouts/ComingSoon'))
);
const ReportProblem = Loadable(
  lazy(() => import('../pages/dashboard/ReportProblem'))
);
const Requests = Loadable(lazy(() => import('../pages/dashboard/Requests')));
const MyContacts = Loadable(
  lazy(() => import('../pages/dashboard/MyContacts'))
);
const TrackTrace = Loadable(
  lazy(() => import('../pages/dashboard/TrackTrace'))
);
const NotificationListItem = Loadable(
  lazy(() => import('../pages/dashboard/NotificationListItem'))
);
const AppSetting = Loadable(
  lazy(() => import('../pages/dashboard/AppSetting'))
);
const BusinessContact = Loadable(
  lazy(() => import('../pages/dashboard/BusinessContact'))
);
const ContactUs = Loadable(lazy(() => import('../pages/dashboard/ContactUs')));
const EmployeeAvailability = Loadable(
  lazy(() => import('../pages/dashboard/EmployeeAvailability'))
);
const BusinessProfile = Loadable(
  lazy(() => import('../pages/dashboard/BusinessProfile'))
);
const NoAuthContactUs = Loadable(
  lazy(() => import('../pages/dashboard/NoAuthContactUs'))
);
const PrivacyPolicy = Loadable(
  lazy(() => import('../pages/dashboard/PrivacyPolicy'))
);
const TermsCondition = Loadable(
  lazy(() => import('../pages/dashboard/TermsCondition'))
);

const BusinessChildPolicy = Loadable(
  lazy(() => import('../pages/dashboard/BusinessChildPolicy'))
);



const NotFound = Loadable(lazy(() => import('../pages/Page404')));
export default function Router() {
  return useRoutes([


    // Public routes for Privacy Policy and Terms & Conditions
    {
      path: 'privacy-policy',
      element: <PrivacyPolicy />, // No guard, accessible by anyone
    },
    {
      path: 'terms-condition',
      element: <TermsCondition />, // No guard, accessible by anyone
    },
    {
      path: 'business-child-policy',
      element: <BusinessChildPolicy />, // No guard, accessible by anyone
    },



    //auth routes
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <Signup />
        </GuestGuard>
      ),
    },
    {
      path: 'verification',
      element: (
        <GuestGuard>
          <VerifyCode />
        </GuestGuard>
      ),
    },
    {
      path: 'forgot-password',
      element: (
        <GuestGuard>
          <ForgotPassword />
        </GuestGuard>
      ),
    },
    {
      path: 'reset-password',
      element: (
        <GuestGuard>
          <ResetPassword />
        </GuestGuard>
      ),
    },
    {
      path: 'home/contact-us',
      element: (
        <GuestGuard>
          <NoAuthContactUs />
        </GuestGuard>
      ),
    },


    //Test page rout
    {
      path: '/test',
      element: (
        <AuthGuard>
          <HeaderLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/',
          element: <></>,
        },
      ],
    },

    // Home Route
    {
      path: '/',
      element: (
        <GuestGuardRole>
          <MapLayout />
        </GuestGuardRole>
      ),
      children: [
        { path: '/', element: <Navigate to="/home" replace /> },
        { path: 'home', element: <Home /> },
      ],
    },

    //User Profile Route
    {
      path: '/user/profile',
      element: (
        <RoleBasedGuard accessibleRoles={[UserRole.USER, UserRole.BUSINESS]}>
          <HeaderLayout />
        </RoleBasedGuard>
      ),
      children: [
        {
          path: '/',
          element: <UserProfile />,
        },
      ],
    },
    //Business Profile Route
    {
      path: '/business/profile',
      element: (
        <RoleBasedGuard accessibleRoles={[UserRole.USER, UserRole.BUSINESS]}>
          <HeaderLayout />
        </RoleBasedGuard>
      ),
      children: [
        {
          path: '/',
          element: <BusinessProfile />,
        },
      ],
    },

    //comming soon Route
    {
      path: '/comming-soon',
      element: (
        <AuthGuard>
          <HeaderLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/',
          element: <ComingSoon />,
        },
      ],
    },

    //Chat Route
    {
      path: '/chat',
      element: (
        <AuthGuard>
          <HeaderLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Chat /> },
        // { path: '/', element: <ChatVol2 /> },
        // { path: '/vol2', element: <ChatVol2 /> },
        { path: '/:type/:id/:chatCategoryId', element: <Chat /> }, // type: business, business-chat, group
        { path: '/:type/:id', element: <Chat /> }, // type: business, business-chat, group
        { path: '/:id', element: <Chat /> },
      ],
    },

  // New Chat Routes
    
    {
      path: "/chat-vol2",
      element:(
        <AuthGuard>
          <HeaderLayoutVol2 />
        </AuthGuard>
      ),
      children: [
        { path: "/", element: <ChatVol2 /> }
      ]
    },

    //Dashboard Routes
    {
      path: 'dashboard/',
      element: (
        <AuthGuard>
          <MasterLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'profile-settings',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.USER]}>
              <ProfileSettings />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'trust-settings',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.USER]}>
              <TrustSettings />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'id-verify',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.USER]}>
              <IDVerification />
            </RoleBasedGuard>
          ),
        },
        // {
        //     path: "selfi-verify",
        //     element: (
        //         <RoleBasedGuard accessibleRoles={[UserRole.USER]}>
        //             <SelfiVerification />
        //         </RoleBasedGuard>
        //     ),
        // },
        {
          path: 'permission',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.USER]}>
              <Permissions />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'set-permission',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.USER]}>
              <SetMultiplePermission />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'requests',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.USER]}>
              <Requests />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'my-contacts',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.USER]}>
              <MyContacts />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'track-trace',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.USER]}>
              <TrackTrace />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'notification',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.USER]}>
              <NotificationListItem />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'app-setting',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.USER]}>
              <AppSetting />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'reference',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.USER]}>
              <References />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'address',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.USER]}>
              <HomeAddress />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'general-settings',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.USER]}>
              <GeneralSetting />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'social',
          element: (
            <RoleBasedGuard
              accessibleRoles={[UserRole.USER, UserRole.BUSINESS]}
            >
              <Social />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'feed-detail/:id',
          element: (
            <RoleBasedGuard
              accessibleRoles={[UserRole.USER, UserRole.BUSINESS]}
            >
              <PostDetails />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'employee-availability',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.USER]}>
              <EmployeeAvailability />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'events',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[UserRole.USER, UserRole.BUSINESS]}
                >
                  <Events />
                </RoleBasedGuard>
              ),
            },
            {
              path: '/create-event',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[UserRole.USER, UserRole.BUSINESS]}
                >
                  <CreateEvent />
                </RoleBasedGuard>
              ),
            },
            {
              path: '/event-confirm',
              element: (
                <RoleBasedGuard accessibleRoles={[UserRole.USER]}>
                  <CreateEventConfirmation />
                </RoleBasedGuard>
              ),
            },
            {
              path: '/event-cancel-confirm',
              element: (
                <RoleBasedGuard accessibleRoles={[UserRole.USER]}>
                  <CancelEventConfirmation />
                </RoleBasedGuard>
              ),
            },
            {
              path: '/event-detail',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[UserRole.USER, UserRole.BUSINESS]}
                >
                  <EventDetail />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'employee-request',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.USER]}>
              <EmployeeRequest />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'employee-leave-request',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.USER]}>
              <EmpLeaveRequest />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'employess-availability',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.BUSINESS]}>
              <BusinessEmpAvailability />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'report',
          element: (
            <RoleBasedGuard
              accessibleRoles={[UserRole.USER, UserRole.BUSINESS]}
            >
              <ReportProblem />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'contact-us',
          element: (
            <RoleBasedGuard
              accessibleRoles={[UserRole.USER, UserRole.BUSINESS]}
            >
              <ContactUs />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'location-log',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.USER]}>
              <LocationLog />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'create-business',
          element: (
            <SingleTimeBusinessCreateGuard>
              <CreateBusiness />
            </SingleTimeBusinessCreateGuard>
          ),
        },
        {
          path: 'business-profile',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.BUSINESS]}>
              <BusinessProfileSetting />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'business-id-verify',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.BUSINESS]}>
              <BusinessIDVerification />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'business-permission',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.BUSINESS]}>
              <BusinessPermissions />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'business-setting',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.BUSINESS]}>
              <BusinessGeneralSetting />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'employee',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.BUSINESS]}>
              <Employee />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'business-address',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.BUSINESS]}>
              <BusinessAddress />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'business-contact',
          element: (
            <RoleBasedGuard accessibleRoles={[UserRole.BUSINESS]}>
              <BusinessContact />
            </RoleBasedGuard>
          ),
        },
      ],
    },

    //page 404
    { path: '404', element: <NotFound /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
