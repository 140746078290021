import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  BUSINESS_API_URL,
  EMPLOYEE_API_URL,
  EVENT_API_URL,
  INDIVIDUAL_API_URL,
  USER_API_URL,
} from 'src/api/axios';
import useStore, { authStore } from 'src/contexts/AuthProvider';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import { UserRole } from 'src/utils/enum';
import MapSidebar from '../home/MapSidebar';
import { getDistanceInKm } from 'src/helpers/helpers';
import { useSnackbar } from 'notistack';
import mapStore from 'src/contexts/mapStore';
import MapCategories from '../home/MapCategories';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import MapSidebarViewProfile from '../home/MapSidebarViewProfile';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import usePopover from 'src/hooks/usePopover';
import { Popover } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { saveUserData } from 'src/redux/reducers/user/UserReducer';

const MapSideBarLayout = (props) => {
  const { filterLatLong, isNewSearch } = props;
  const [lattitudeData, setLattitudeData] = useState();
  const [longitudeData, setLongitudeData] = useState();
  // const [lattitudeData, setLattitudeData] = useState(-30.5595);
  // const [longitudeData, setLongitudeData] = useState(22.9375);
  const [otherUserId, setOtherUserId] = useState();
  const [otherBusinessId, setOtherBusinessId] = useState();
  const [otherBusinessAddressId, setOtherBusinessAddressId] = useState('');
  const [lottiPlayerLoder, setLottiPlayerLoder] = useState(true);
  const [businessStatus, setBusinessStatus] = useState(false);
  const [groupStatus, setGroupStatus] = useState(false);
  const [groupData, setGroupData] = useState();
  const [storeFilterValue, setStoreFilterValue] = useState({});
  const navigate = useNavigate();
  const check = mapStore((state) => state.updateUserLocation);
  const eventDataMap = mapStore((state) => state.updateEventLocation);
  const setPolylineData = mapStore((state) => state.setPolylineData);
  const setShowPolyline = mapStore((state) => state.setShowPolyline);
  const { anchorEl, handleClick, handleClose, open } = usePopover();

  const userData = useStore((state) => state.user);
  const axiosPrivate = useAxiosPrivate();
  const role = authStore((state) => state.role);

  const [categoryList, setcategoryList] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const [searchValue, setSearchValue] = useState('');
  const [searchUserData, setSearchUserData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [eventFilteredData, setEventFilteredData] = useState([]);
  const [selectedMapOption, setSelectedMapOption] = useState('all');
  const [showMapEvents, setShowMapEvents] = useState(false);
  const setIsGuestContinue = authStore((state) => state.setIsGuestContinue);

  const toastMessage = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      autoHideDuration: 2000,
    });
  };

  // const geoLocation = () => {
  //   navigator.geolocation.getCurrentPosition((position) => {
  //       setLattitudeData(position.coords.latitude);
  //       setLongitudeData(position.coords.longitude);
  //   });
  // };
  // const fetchIPLocation = async () => {
  //   try {
  //     const response = await fetch("https://ipapi.co/json/");
  //     const data = await response.json();

  //     if (data && data.latitude && data.longitude) {
  //     setLattitudeData(data.latitude);
  //     setLongitudeData(data.longitude);
  //     } else {
  //       throw new Error("IP location data is not available");
  //     }
  //   } catch (err) {
  //     console.error("Error fetching IP location:", err);
  //     // setError(err);
  //   }
  // };

  const fetchIPLocation = async () => {
    try {
      // const response = await fetch("https://ipapi.co/json/");
      const response = await fetch("https://api.ipregistry.co?key=tmfeo32hmi80xmyn")
      const data = await response.json();
      console.log('data: Home location', data.location);

      if (data && data?.location?.latitude && data?.location?.longitude) {
      setLattitudeData(data?.location?.latitude);
      setLongitudeData(data?.location?.longitude);
      } else {
        throw new Error("IP location data is not available");
      }
    } catch (err) {
      console.error("Error fetching IP location:", err);
      // setError(err);
    }
  };


  const geoLocation = () => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          // console.log("Result", result);
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition((position) => {
              setLattitudeData(position.coords.latitude);
              setLongitudeData(position.coords.longitude);
            });
          } else if (result.state === "denied") {
            fetchIPLocation();
          }
        })
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  useEffect(() => {
    geoLocation();
  }, []);

  useEffect(() => {
    if (isNewSearch) {
      if (filterLatLong) {
        setLattitudeData(filterLatLong.lat);
        setLongitudeData(filterLatLong.lng);
      }
    }
  }, [filterLatLong, isNewSearch]);

  useEffect(() => {
    if (filteredData.length > 0) {
      check(filteredData);
    } else {
      check(searchUserData);
    }
  }, [searchUserData, check, filteredData]);


  const dispatch = useDispatch();
  const userProfileData = useSelector((state) => state.user.userData);
   useQuery(
    'userProfileSetting',
    async ({ signal }) => {
      return await axiosPrivate
        .get(INDIVIDUAL_API_URL.profile, { signal })
        .then((res) => res.data);
    },
    {
      refetchOnWindowFocus: false, onSuccess: (data) => {
        dispatch(saveUserData(data));
      }
    }
  );

  // const { data: userProfileData } = useQuery(
  //   'userProfileSetting',
  //   async ({ signal }) => {
  //     if (role !== UserRole.GUEST) {
  //       return await axiosPrivate
  //         .get(INDIVIDUAL_API_URL.profile, { signal })
  //         .then((res) => res.data);
  //     }
  //     return {};
  //   },
  //   { refetchOnWindowFocus: false }
  // );

  const handlePolyline = (user) => {
    if (userProfileData && userProfileData?.address?.location) {
      setTimeout(() => {
        setShowPolyline(true);
      }, 1000);
    } else {
      toastMessage(props.t('toast_message.not_fetch_location'), 'error');
    }
  };

  const handleSearchUser = (value) => setSearchValue(value.toString());

  const handleDirection = (user) => {
    if (userProfileData?.permissions?.location?.notShared) {
      enqueueSnackbar(props.t('toast_message.give_location_permission'), {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        autoHideDuration: 2000,
      });
    } else {
      setPolylineData(user);
      handlePolyline(user);
    }
  };

  const { data: userLocationData, refetch: userLocation } = useQuery(
    [
      'userLocation',
      longitudeData,
      lattitudeData,
      searchValue,
      selectedMapOption,
    ],
    async ({ signal }) => {
      if (!longitudeData || !lattitudeData) return;
      return await axiosPrivate
        .get(USER_API_URL.getLocation, {
          signal,
          params: {
            long: Number(longitudeData),
            lat: Number(lattitudeData),
            range: 30,
            skipId: userData?._id,
            type: ['all', 'people', 'group'].includes(selectedMapOption)
              ? selectedMapOption
              : 'business',
            categoryId: ['all', 'people', 'group'].includes(selectedMapOption)
              ? null
              : selectedMapOption,
            search: searchValue ? searchValue : '',
          },
        })
        .then((res) => res.data)
        .catch((err) => console.error(err));
    },
    { refetchOnWindowFocus: false, enabled: !!longitudeData && !!lattitudeData }
  );

  const { data: userEventAllData, refetch: userRefetchEventAllData } = useQuery(
    [
      'userEventAllData',
      longitudeData,
      lattitudeData,
      selectedMapOption,
      searchValue,
    ],
    async ({ signal }) => {
      if (!longitudeData || !lattitudeData) return;
      if (selectedMapOption === 'event' || selectedMapOption === 'all') {
        return await axiosPrivate
          .get(EVENT_API_URL.getAllEvent, {
            signal,
            params: {
              long: Number(longitudeData),
              lat: Number(lattitudeData),
              search: searchValue ? searchValue : '',
              range: 30,
              isMap: 1,
            },
          })
          .then((res) => res.data);
      } else {
        return [];
      }
    },
    { refetchOnWindowFocus: false, enabled: !!longitudeData && !!lattitudeData }
  );

  const { mutateAsync: searchPlace } = useMutation(
    async (data) => {
      if (!_.isEmpty(data?.keyword)) {
        return await axiosPrivate.post(USER_API_URL.searchPlace, data);
      }
    },
    {
      onSuccess: (res) => {
        if (res) {
          let array = [];
          res.data &&
            res.data.length > 0 &&
            res.data.map((item) => {
              const distance = getDistanceInKm(
                userProfileData?.address?.location?.coordinates[1],
                userProfileData?.address?.location?.coordinates[0],
                item.geometry?.location?.lat ?? 0.0,
                item.geometry?.location?.lng ?? 0.0
              );
              array.push({
                id: item.place_id,
                address: item.formatted_address,
                location: {
                  type: 'geo',
                  coordinates: [
                    item.geometry?.location?.lng ?? 0.0,
                    item.geometry?.location?.lat ?? 0.0,
                  ],
                },
                image: item.icon ?? '',
                name: item.name ?? '',
                phone_data: item.phone_data ?? '',
                photos: item.photos ?? '',
                averageTrust: item.rating ?? 0,
                userStatus: '',
                selfAsContact: false,
                status: 0,
                distance: distance ?? 0.0,
                businessCatId: item.businessCatId ?? '',
              });
            });
          if (array) {
            if (searchUserData) {
              setSearchUserData(_.concat(searchUserData, array));
            }
          }
        }
      },
      onError: (error) => {
        if (error?.response)
          toastMessage(error?.response?.data?.message, 'error');
      },
    }
  );
  const nearbySearch = async () => {
    if (longitudeData && lattitudeData) {
      let location = `${Number(lattitudeData)},${Number(longitudeData)}`;
      let obj = { location: location, keyword: searchValue };
      setTimeout(() => {
        searchPlace(obj);
      }, 1000);
    }
  };

  useEffect(() => {
    if (userLocationData) {
      setSearchUserData(userLocationData);
      setFilteredData(userLocationData);
      const cate = userLocationData
        .filter((categoryData) => categoryData.category)
        .map((catData, index) => {
          return catData.category;
        });
      // userLocationData.filter((data) => data.userId && cate.unshift({ _id: "people", name: "People" }));
      const filterCat = cate.filter((cat) => Object.keys(cat).length !== 0);
      let unique = filterCat.filter(
        (v, i, a) => a.findIndex((t) => t._id === v._id) === i
      );
      if (categoryList.length === 0) setcategoryList(unique);
      setcategoryList(unique);
    }
  }, [userLocationData]);

  useEffect(() => {
    // userLocation();
    setTimeout(() => {
      nearbySearch();
    }, 1000);
  }, [searchValue, longitudeData]);

  const handleFiltering = (data) => {
    setStoreFilterValue(data);
    let filterArray = [];
    let evenFilterArray = [];
    if (data && data.distance && data.trustLevel.length > 0) {
      filterArray = searchUserData.filter(
        (item) =>
          item.distance <= data.distance &&
          data.trustLevel.includes(item.averageTrust)
      );
      evenFilterArray = userEventAllData.filter(
        (item) =>
          item.distance <= data.distance && data.trustLevel.includes(item.trust)
      );
    } else if (data && data.distance) {
      filterArray = searchUserData.filter(
        (item) => item.distance <= data.distance
      );
      evenFilterArray = userEventAllData.filter(
        (item) => item.distance <= data.distance
      );
    } else if (data && data.trustLevel.length > 0) {
      filterArray = searchUserData.filter((item) =>
        data.trustLevel.includes(item.averageTrust)
      );
      evenFilterArray = userEventAllData.filter((item) =>
        data.trustLevel.includes(item.trust)
      );
    }
    setFilteredData(filterArray);
    setEventFilteredData(evenFilterArray);
  };
  const handleResetFiltering = () => {
    setStoreFilterValue({});
    setFilteredData([]);
    setEventFilteredData([]);
  };
  const handleMapOnCatOnChange = (cat) => {
    setSelectedMapOption(cat);
    if (cat === 'posts') {
      if (role === UserRole.GUEST) {
        setIsGuestContinue(true);
      }
      // Redirecting to post page without passing the state
      navigate(PATH_DASHBOARD.general.social);
    }
  };
  const handleMapEventCategory = () => {
    setShowMapEvents(true);
  };
  const handleEventSidebarClose = () => {
    setShowMapEvents(false);
  };

  const mapCatData = {
    categoryList: categoryList,
    selectedMapOption: selectedMapOption,
    mapCatUpdateFunc: handleMapOnCatOnChange,
    userDataLength: userLocationData?.length,
    mapShowEventfunc: handleMapEventCategory,
    mapCloseEventfunc: handleEventSidebarClose,
    userEventLength: userEventAllData?.length,
  };

  useEffect(() => {
    if (selectedMapOption) {
      userLocation();
    }
  }, [selectedMapOption]);

  const handleViewProfileClose = () => {
    handleClose();
  };

  const {
    data: otherUserData,
    refetch: otherProfileUserData,
    isLoading: userLoder,
  } = useQuery(
    ['otherProfileUserData', otherUserId],
    async ({ signal }) => {
      if (otherUserId) {
        return await axiosPrivate
          .get(INDIVIDUAL_API_URL.otherProfileNotParam + otherUserId, {
            signal,
          })
          .then((res) => res.data);
      }
    },
    { refetchOnWindowFocus: false }
  );

  const {
    data: otherBusinessData,
    refetch: otherProfileBusinessData,
    isLoading: businessLoder,
  } = useQuery(
    ['otherProfileBusinessData', otherBusinessId],
    async ({ signal }) => {
      if (otherBusinessId) {
        return await axiosPrivate
          .get(BUSINESS_API_URL.otherProfileNotParam + otherBusinessId, {
            signal,
            params: { addressId: otherBusinessAddressId },
          })
          .then((res) => res.data);
      }
    },
    { refetchOnWindowFocus: false }
  );

  const { data: businessAddress, refetch: businessAddressData } = useQuery(
    ['businessAddressData', otherBusinessId],
    async ({ signal }) => {
      if (otherBusinessId) {
        return await axiosPrivate
          .get(BUSINESS_API_URL.businessAddress, {
            signal,
            params: { businessId: otherBusinessId },
          })
          .then((res) => res.data);
      }
    },
    { refetchOnWindowFocus: false }
  );

  const { data: businessEmployee, refetch: businessEmployeeData } = useQuery(
    ['businessEmployeeData', otherBusinessAddressId],
    async ({ signal }) => {
      if (otherBusinessAddressId) {
        return await axiosPrivate
          .get(EMPLOYEE_API_URL.root, {
            signal,
            params: {
              businessId: otherBusinessId,
              addressId: otherBusinessAddressId,
            },
          })
          .then((res) => res.data);
      }
    },
    { refetchOnWindowFocus: false }
  );

  const handleViewProfileSidebarOnclick = (ev, data) => {
    handleClick(ev);
    if (data?.isBusiness) {
      setOtherBusinessId(data?.dataId);
      setOtherBusinessAddressId(data?.addressId);
      businessEmployeeData();
      setBusinessStatus(true);
      setGroupStatus(false);
      otherProfileBusinessData();
      businessAddressData();
    } else if (data?.isGroup) {
      setOtherBusinessAddressId();
      setGroupData(data?.groupData);
      setGroupStatus(true);
      setBusinessStatus(false);
    } else {
      setOtherBusinessAddressId();
      setOtherUserId(data?.dataId);
      otherProfileUserData();
      setBusinessStatus(false);
      setGroupStatus(false);
    }
  };

  useEffect(() => {
    setLottiPlayerLoder(userLoder);
  }, [userLoder]);

  useEffect(() => {
    setLottiPlayerLoder(businessLoder);
  }, [businessLoder]);

  useEffect(() => {
    if (!_.isEmpty(groupData)) {
      setLottiPlayerLoder(false);
    }
  }, [groupData]);

  useEffect(() => {
    if (userEventAllData?.length > 0) {
      eventDataMap(userEventAllData);
    } else {
      eventDataMap([]);
    }
  }, [userEventAllData]);
  useEffect(() => {
    if (eventFilteredData?.length > 0) {
      eventDataMap(eventFilteredData);
    }
  }, [eventFilteredData]);

  // console.log("DEBUG userLocationData", userLocationData);

  return (
    <div className="map_sidebar_layout_wrapper">
      {/* {showMapEvents ? (
        <MapSidebarEvent
          userEventAllData={userEventAllData}
          closeFunc={handleEventSidebarClose}
          mapCatUpdateFunc={handleMapOnCatOnChange}
        />
      ) : ( */}
      <MapSidebar
        userLocationData={searchUserData}
        handleDirection={handleDirection}
        handleSearchUser={handleSearchUser}
        handleFiltering={handleFiltering}
        userLocation={userLocation}
        filteredData={filteredData}
        hanldeViewProfile={handleViewProfileSidebarOnclick}
        handleResetFiltering={handleResetFiltering}
        userEventAllData={userEventAllData}
        eventFilteredData={eventFilteredData}
        categoryData={categoryList}
        handleViewProfileClose={handleViewProfileClose}
      />
      {/* )} */}
      <MapCategories {...mapCatData} />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '16px !important',
            maxWidth: { xsm: 410, xs: 300 },
            width: { xsm: 'calc(100% - 42px)', xs: 'calc(100% - 10px)' },
            marginLeft: { xsm: '42px', xs: '10px' },
          },
        }}
      >
        <MapSidebarViewProfile
          lottiPlayerLoder={lottiPlayerLoder}
          businessAddress={businessAddress}
          businessEmployee={businessEmployee}
          groupStatus={groupStatus}
          groupData={groupData}
          businessStatus={businessStatus}
          otherBusinessData={otherBusinessData}
          otherUserData={otherUserData}
          onCloseFunc={handleViewProfileClose}
        />
      </Popover>
    </div>
  );
};

export default withTranslation()(MapSideBarLayout);
