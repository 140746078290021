import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: null,
  accessToken: null,
  vol2Token: "",
  vol2User: null
};

const UserReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveUserData: (state, action) => {
      state.userData = action.payload;
    },
    removeUserData: (state) => {
      state.userData = null;
      state.accessToken = null;
    },
    setAuthToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setVol2Token: (state, action) => {
      state.vol2Token = action.payload
    },
    setVol2User: (state, action) => {
      state.vol2User = action.payload
    }
  },
});

export const {
  saveUserData,
  removeUserData,
  setAuthToken,
  setVol2Token,
  setVol2User
} = UserReducer.actions;

export default UserReducer.reducer;
